<span class="search" [ngClass]="{'slide-in-blurred-right': isSearchOpen}">
    <app-search-input (blur)="onFocusChange()"
                      #input
                      [autoFocus]="false"
                      tabindex="2"
                      (close)="close()"
                      [closeVisible]="true"
                      (searchTerm)="searchTerm = $event; textChange.emit($event)"
                      [inputValue]="searchTerm"></app-search-input>
</span>
<span class="kit">
  <app-icon-button icon='search' class="button"
                   [ngClass]="{'_disabled': disabled}"
                   *ngIf="compact && !isSearchOpen"
                   (click)="searchClick()">
  </app-icon-button>
</span>
