import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent implements OnInit {
  @Input() public minutes = null;
  @Output() public readonly onChange: EventEmitter<any> = new EventEmitter();
  @Input() public disabled = false;
  @Input() public superactive = false;

  public h = null;
  public m = null;

  public hFocused = false;
  public mFocused = false;

  @ViewChild('hourEl') hourEl: ElementRef;
  @ViewChild('minEl') minEl: ElementRef;

  get focused() {
    return this.hFocused || this.mFocused;
  }

  ngOnInit(): void {
    if (this.minutes) {
      this.m = this.minutes % 60;
      this.h = (this.minutes - this.m) / 60;

      if (this.h < 10) {
          this.h = '0' + this.h;
      }

      if (this.m < 10) {
          this.m = '0' + this.m;
      }
    }
  }

  onChangeHours(evt) {
    let str = this.h?.toString();

    if (isNaN(str) && str) {
      this.hourEl.nativeElement.value = str.slice(0, str.length - 1);
    }

    if (str && str.length > 2) {
      this.h = str.slice(0, 2);
    }

    str = this.h?.toString();

    if (str && str.length == 2 && evt.target.selectionStart == 2) {
      this.hourEl.nativeElement.value = str;
      this.focusMinutes();
    }

    if (this.superactive) {
      this.save()
    }
  }

  onChangeMinutes(evt) {
    let str = this.m?.toString();

    if (isNaN(str) && str) {
      this.minEl.nativeElement.value = str.slice(0, str.length - 1);
    }

    if (str && str > 59) {
      this.m = 59;
    }

    str = this.m?.toString();
    if (str && str.length > 2) {
      this.m = str.slice(0, 2);
    }

    if (!str) {
      this.focusHours()
    }

    if (this.superactive) {
      this.save()
    }
  }

  focusMinutes() {
    this.minEl.nativeElement.focus();
    this.minEl.nativeElement.selectionStart = 0;
  }

  focusHours() {
    this.hourEl.nativeElement.focus();
  }

  save() {
    setTimeout(() => {
      if (!this.focused || this.superactive) {
        const sum = ((+this.h) * 60) + (+this.m);

        this.onChange.emit(sum);
      }

      if (!this.focused) {
        if ((this.h || this.h == 0) && this.h.length == 1) {
          this.h = '0' + this.h;
        }

        if ((this.m || this.m == 0) && this.m.length == 1) {
          this.m = '0' + this.m;
        }
      }
    })
  }
}
