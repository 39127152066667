import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    public currentSidebar$ = new BehaviorSubject<string | null>(null);

    changeSidebar(newSidebar: string) {
        this.currentSidebar$.next(newSidebar);
    }

    closeSidebar() {
        this.currentSidebar$.next(null);
    }
}
