import {JSON, JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../../models/user';
import {ExecutiveMatrix} from './executive-matrix';
import {Matrix} from './matrix';
import {UsersMotivation} from '../motivations/users-motivation';
import {Motivation} from '../motivations/motivation';

export class MatrixUserRespElement {
    @JsonProperty('id')
    @JsonElementType(Number)
    id?: any;

    @JsonProperty('matrix')
    @JsonElementType(Matrix)
    matrix?: Matrix;

    @JsonProperty('motivation_id')
    @JsonElementType(Number)
    motivationId?: number;

    @JsonProperty('reason')
    @JsonElementType(String)
    reason?: string;

    @JsonProperty('can_edit')
    @JsonElementType(Boolean)
    canEdit?: boolean;

    static deserializeDictionary(dict): MatrixUserRespElement[] {
        const result = [];

        for (const userId in dict) {
            if (dict.hasOwnProperty(userId)) {
                const userData = dict[userId];

                result.push({
                    id: +userId,
                    matrix: userData.matrix ? JSON.deserialize<Matrix>(userData.matrix, Matrix) : null,
                    motivationId: userData.motivation_id,
                    reason: userData.reason,
                    canEdit: userData.can_edit
                });
            }
        }

        return result;
    }
}

export class MatrixEmployment extends UsersMotivation {
    @JsonProperty('matrices')
    @JsonType(MatrixUserRespElement)
    matrices: MatrixUserRespElement[];
}

export class MatrixEmployee extends UserShort {
    @JsonProperty('employments')
    @JsonType(MatrixEmployment)
    employments: MatrixEmployment[];
}

export class MatrixListItem {
    user: UserShort;
    subdivisionId: number;
    type: string;
    emp: UsersMotivation;
    motivation: Motivation;
    matrix: Matrix;
    attention: string;

    bonus: MatrixListItem[];
    parent: MatrixListItem;
}

export class MatrixSubdivision {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parentId: number;

    @JsonProperty('is_archive')
    @JsonType(Boolean)
    isArchive: boolean;

    @JsonProperty('users_count')
    @JsonType(Number)
    usersCount: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('company_id')
    @JsonType(Number)
    companyId: number;

    @JsonProperty('image')
    @JsonType(String)
    image: string;

    @JsonProperty('executives')
    @JsonElementType(UserShort)
    executive: ExecutiveMatrix[];

    @JsonProperty('deputy')
    @JsonElementType(UserShort)
    deputy: UserShort;

    @JsonProperty('children')
    @JsonElementType(MatrixSubdivision)
    children: MatrixSubdivision[];

    @JsonProperty('countPath')
    @JsonElementType(Number)
    countPath: number;

    matrix: MatrixListItem[];

    attention: string = '';

    makeTreeNode(list) {
        const map = {};
        const roots = [];

        if (list.length === 1) {
            return list;
        }

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i]['children'] = [];
            list[i]['countPath'] = 1;
        }

        function getParent(node) {
            return list[map[node.parentId]];
        }

        function isRoot(node) {
            return node.parentId === undefined || node.parentId === null ||
                node.parentId === node.id ||
                !getParent(node);
        }

        for (let i = 0; i < list.length; i += 1) {
            const node = list[i];

            if (!isRoot(node)) {
                // count path length to root
                for (let n = node; !isRoot(n); n = getParent(n)) {
                    list[i]['countPath']++;
                }

                list[map[node.parentId]].children
                    ? list[map[node.parentId]].children.push(node)
                    : (list[map[node.parentId]].children = [node]);

                node['parent'] = list[map[node.parentId]];
            } else {
                roots.push(node);
            }
        }
        roots.some((el) => el.parentId);
        return roots;
    }
}

export class MatrixEmployeeNew extends UserShort {
    @JsonProperty('employments')
    @JsonType(MatrixEmployment)
    employments: MatrixEmployment[];

    subdivisionId?: number;
}



export class MatrixSubdivisionNew {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parentId: number;

    @JsonProperty('is_archive')
    @JsonType(Boolean)
    isArchive: boolean;

    @JsonProperty('users_count')
    @JsonType(Number)
    usersCount: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('company_id')
    @JsonType(Number)
    companyId: number;

    @JsonProperty('image')
    @JsonType(String)
    image: string;

    @JsonProperty('executives')
    @JsonElementType(ExecutiveMatrix)
    executive: ExecutiveMatrix;

    @JsonProperty('deputy')
    @JsonElementType(UserShort)
    deputy: UserShort;

    @JsonProperty('children')
    @JsonElementType(MatrixSubdivisionNew)
    children: MatrixSubdivisionNew[];

    @JsonProperty('countPath')
    @JsonElementType(Number)
    countPath: number;

    @JsonProperty('employees')
    @JsonElementType(MatrixEmployeeNew)
    employees: MatrixEmployeeNew[];

    attention: boolean;
    static makeTreeNode(list) {
        const map = {};
        const roots = [];

        function hasAttention(subd) {
            if (!subd.employees) {
                return;
            }
            for (let u of subd.employees) {
                for (let e of u.employments) {
                    for (let m of e.matrices) {
                       const mat: Matrix = m.matrix;
                       if (mat && mat.canApprove) return true;
                    }
                }
            }

            return false;
        }

        function hasAttentionChild(subd) {
            for (let su of subd.children) {
                if (su.attention) {
                    return true;
                }
            }

            return false;
        }

        if (list.length === 1) {
            return list;
        }

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i]['children'] = [];
            list[i]['attention'] = hasAttention(list[i]);
        }

        for (let i = 0; i < list.length; i += 1) {
            const node = list[i];
            const parentId = node.parentId;
            if (parentId !== node.id && list[map[parentId]]) {
                list[map[parentId]]['children'] ?
                    list[map[parentId]]['children'].push(node) :
                    list[map[parentId]]['children'] = [node];

                list[map[parentId]]['attention'] = hasAttentionChild(list[map[parentId]]);
            } else {
                roots.push(node);
            }
        }

        return roots;
    }
}

export class MatrixUser {
    id: any;
    tooltip = null;
    motivation: number = null;
    matrix: Matrix = null;
    canAddToMatrix = false;
    canEdit = false;
}
