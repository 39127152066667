import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserShort} from '../../../../../models/user';
import {ConfigBoardsService} from '../../../../task-manager/services/config-boards.service';
import {AuthService} from '../../../../../services/auth.service';

@Component({
  selector: 'app-person-item',
  templateUrl: './person-item.component.html',
  styleUrls: ['./person-item.component.scss']
})
export class PersonItemComponent implements OnInit {

  @Input() size = 'NORMAL';

  @Output() onUserClick = new EventEmitter<UserShort>();
  @Input() public user: UserShort = null;
  @Input() public shortView = false;
  @Input() public action: string = null;
  @Input() public more = false;
  @Input() public title = true;
  @Input() public isResponsible = false;
  @Input() public disabled = false;
  @Input() showName = true;

  public userId = null;
  public tooltipMessage = '';

  constructor(
      private config: ConfigBoardsService,
      private auth: AuthService
  ) {
  }

  onClick() {
    if (this.action) {
      this.onUserClick.emit(this.user);
    }
  }

  ngOnInit() {
    this.userId = this.auth.currentUser$.value.id;
    this.tooltipMessage =  this.title ? this.user.shortName : ''
  }

  openTooltip(tooltip) {
    tooltip.show()
    console.log(this.user);
    this.tooltipMessage = this.user['disabled'] ? this.user['noti'] : this.title ? this.user.shortName : ''

    setTimeout(() => {
      this.tooltipMessage = this.title ? this.user.shortName : ''
    }, 5000)
  }
}
