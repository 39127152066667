import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class SettingsPhonebook {
    @JsonProperty('view')
    @JsonType(String)
    view: string;

    @JsonProperty('sort')
    @JsonType(String)
    sort: string;

    @JsonProperty('subdivisionsFilter')
    @JsonType(String)
    subdivisionsFilter: any;

    @JsonProperty('noMailFilter')
    @JsonType(Boolean)
    noMailFilter: boolean;


    @JsonProperty('hiddenUsersFilter')
    @JsonType(Boolean)
    hiddenUsersFilter: boolean;

    @JsonProperty('notConfirmMailFilter')
    @JsonType(Boolean)
    notConfirmMailFilter: boolean;

    constructor(
        view: string = 'LIST',
        sort: string = 'ABC',
        noMailFilter = false,
        notConfirmMailFilter = false,
        hiddenUsersFilter = false,
        subdivisionsFilter = [],
    ) {
        this.view = view;
        this.sort = sort;
        this.noMailFilter = noMailFilter;
        this.notConfirmMailFilter = notConfirmMailFilter;
        this.hiddenUsersFilter = hiddenUsersFilter;
        this.subdivisionsFilter = subdivisionsFilter;
    }

    isOn(filter) {
        return !!(filter && (filter.subdivisionsFilter && filter.subdivisionsFilter.length
            || filter.hiddenUsersFilter|| filter.noMailFilter || filter.notConfirmMailFilter));
        // return !!(filter && (filter.notConfirmMail || filter.noMail || (filter.subdivisions && filter.subdivisions.length)));
    }
}
