<app-header
    (openChats)="openChats()"
    (openNotificationCenter)="openNotifications()"
    (openQuestions)="openQuestions()"
></app-header>

<div class="main-container">
    <app-top-message
        class="grey"
        (close)="clearActivityTimer()"
        *ngIf="activityTimer"
    >
        Ваша страница устарела
        <a
            class="link"
            (click)="refresh()"
        >
            Обновить
        </a>
    </app-top-message>

    <app-top-message
        class="grey"
        (close)="ignoreDisconnect()"
        *ngIf="status == 'OFFLINE' && !ignoringDisconnect"
    >
        Соединение с сетью потеряно
        <a
            class="link"
            (click)="refresh()"
        >
            Обновить страницу
        </a>
    </app-top-message>

    <app-top-message
        class="grey"
        (close)="ignoreVersion()"
        *ngIf="versionIsDeprecated && !ignoreVersionDeprecated"
    >
        Версия приложения устарела
        <a
            class="link"
            (click)="refresh()"
        >
            Обновить страницу
        </a>
    </app-top-message>

    <!-- промо тариф кончается -->
    <app-top-message
        *ngIf="showMessage && !showMessageChanged && isPromoPricingPlan"
        (close)="hideMessage()"
    >
        Уважаемый пользователь, уведомляем&nbsp;вас, что заканчивается срок
        действия промо версии и&nbsp;с&nbsp;{{
            formatDatePlusOne(pricingPlanDate)
        }}
        начинается срок действия выбранного и&nbsp;оплаченного вами тарифа.
        Для&nbsp;выбора тарифа перейдите в&nbsp;
        <a
            class="link"
            href="/cabinet-company/pricing"
        >
            Тарифы
        </a>
    </app-top-message>

    <!-- тариф скоро кончится  -->
    <app-top-message
        *ngIf="showMessage && !showMessageChanged && !isPromoPricingPlan"
        (close)="hideMessage()"
    >
        Уважаемый пользователь, уведомляем&nbsp;Вас, что&nbsp;заканчивается срок
        действия оплаченного тарифа {{ formatDate(pricingPlanDate) }}.
        Для&nbsp;продления тарифа перейдите в&nbsp;
        <a
            class="link"
            href="/cabinet-company/pricing"
        >
            Тарифы
        </a>
    </app-top-message>

    <!-- тариф изменён -->
    <app-top-message
        *ngIf="showMessageChanged && isAfter30October2023"
        (close)="hideMessage()"
    >
        Уважаемый пользователь, уведомляем&nbsp;вас, что с&nbsp;{{
            formatDate(pricingPlanChangeDate)
        }}
        Ваш тариф изменен. Для&nbsp;изменения тарифа перейдите в&nbsp;
        <a
            class="link"
            href="/cabinet-company/pricing"
        >
            Тарифы
        </a>
    </app-top-message>

    <router-outlet></router-outlet>
    <app-onboarding-company></app-onboarding-company>
    <app-onboarding-tasks></app-onboarding-tasks>
    <app-learning-tasks></app-learning-tasks>
    <app-onboarding-users></app-onboarding-users>
    <app-onboarding-main></app-onboarding-main>
    <app-onboarding-wnd-common></app-onboarding-wnd-common>

    <router-outlet
        name="chats"
        *ngIf="activeSidebar === SidebarType.CHATS"
    ></router-outlet>
    <router-outlet
        name="notifications"
        *ngIf="activeSidebar === SidebarType.NOTIFICATIONS"
    ></router-outlet>
    <router-outlet
        name="questions"
        *ngIf="activeSidebar === SidebarType.QUESTIONS"
    ></router-outlet>

    <!--<app-chat-rooms *ngIf="openChat" (closeChats)="openChats()"></app-chat-rooms>-->
    <!--<app-notification-center *ngIf="openNotificationCenter" (closeNotificationCenter)="openNotifications()"></app-notification-center>-->
</div>

<!--<app-ads *ngIf="isAbpDetect" (close)="isAbpDetect = false;"></app-ads>-->
<div
    class="disconnect"
    *ngIf="!isConnected"
>
    <mat-icon class="load">sync</mat-icon>
    Идет установка соединения...
</div>
