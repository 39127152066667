import {Injectable} from '@angular/core';
import {Permits} from '../../../models/sections';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {map, takeUntil} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessRightsService {
  getRights$ = new BehaviorSubject<boolean>(false);
  permits$= new BehaviorSubject<Permits[]>(null);

  constructor(
      private http: HttpClient,
      public config: ConfigService,
      public auth: AuthService
  ) { }

  public getSections(): Observable<Permits[]> {
    return this.http.get(
        `${this.config.accessRights}`,
        this.auth.authorize(),
    ).pipe(
        map(res =>  JSON.deserialize<Permits[]>(res['data'], Permits))
    );
  }

  public updateSections(sectionId, data) {
    return this.http.put(
        `${this.config.accessRights}${sectionId}`,
        JSON.serialize(data),
        this.auth.authorize(),
    ).pipe(
        map(res =>  res['data'])
    );
  }
}
