import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TagsColor, tagsMapping} from '../models/tag';
import {Board} from '../models/board';
import {BoardRole} from '../models/member';
import {Filter} from '../models/filter';
import {VIEW} from '../models/view-settings';

@Injectable({
    providedIn: 'root'
})
export class BoardsService {

    tableView$ = new BehaviorSubject(null);
    boards$ = new BehaviorSubject(null);
    separateBoards$ = new BehaviorSubject(null);
    reloadBoard$ = new Subject();
    getBoards$ = new Subject();
    boardOnSubmit$ = new Subject();
    sharedBoardChanged$ = new Subject();
    boardHasChanges$ = new BehaviorSubject(null);
    boardCloseConfirmUrl$ = new BehaviorSubject(null);
    openExportBoardDialog$ = new Subject();
    openExportSprintDialog$ = new Subject();
    openedBoardId$ = new BehaviorSubject(null);
    lastopenedBoardId$ = new BehaviorSubject(null);
    boardSelected$ = new BehaviorSubject<Board>(null);
    boardsSyncSelected$ = new BehaviorSubject(null);
    boardOpened$ = new BehaviorSubject(null);
    boardUpdated$ = new Subject<Board>();
    resetBoard$ = new Subject();
    createdColumn$ = new Subject();
    menuOpened$ = new BehaviorSubject(false);
    countsPerBoard$ = new BehaviorSubject(null);
    showArchive$ = new BehaviorSubject(false);
    showFullHier$ = new Subject();
    boardDateRequired$ = new BehaviorSubject(null);
    isTasksBoardBlocked$ = new BehaviorSubject(false);
    canEditGroup$ = new BehaviorSubject(false);

    planingDescriptionClose$ = new Subject();
    planingTaskVisible$ = new BehaviorSubject(true);
    planingEmptyTaskVisible$ = new BehaviorSubject(true);

    //сначала дергается кнопка архива, потом добавляется новые элементы.
    //Хранит размер рабочей области экрана планировщик
    screenSize$ = new BehaviorSubject(null);
    filtersOpened$ = new BehaviorSubject(false);
    settingsDone$ = new BehaviorSubject(null);

    noticeableOpen$ = new BehaviorSubject(false);

    // Если происходит запрос или драгндроп
    activity$ = new BehaviorSubject(false);
    statisticsOpened$ = new BehaviorSubject(false);
    userMakeSmthn$ = new Subject();

    sort$ = new Subject();

    fieldsNeedToOpen$ = new BehaviorSubject(null);

    shortTaskUpdate$ = new Subject;
    loadingAreaId$ = new BehaviorSubject(null);
    matrixBoard$ = new BehaviorSubject(null);

    openBoardFromCabinet$ = new BehaviorSubject(null);
    globalAdmin$ = new BehaviorSubject(null);
    //Просто индикатор что кто то что то подвинул в иерархии
    hierarchyDropEvent$ = new Subject()

    constructor() {
    }

    getMainBoardOfTask(task, userId) {
        const boardSelected = this.boardSelected$.value;
        const selectedBoard = task.boards.find(el => el.id === boardSelected?.id);
        let board = null;
        if (selectedBoard) {
            return selectedBoard;
        } else {
            const mainId = task.boards.find(el => el.is_main)?.id;
            const personalId = task.boards.find(el => el.id === -userId)?.id;
            let boards = this.boards$.value;

            // Нужна затычка для дашборда где нет досок
            if (!boards || !boards.length) {
               boards = [...task.boards];
            }

            if (mainId) {
                board = boards.find(b => b.id === mainId);
                // Добавить исключение если никого нет на этой доске
                if (!board) {
                    board = task.boards.find(b => b.id === mainId);
                }
            } else {
                if (personalId) {
                    board = boards.find(b => b.id === personalId);
                } else {
                    board = boards.find(b => b.id === task.boards[0]?.id);
                }
            }
        }

        return board;
    }

    selectDefaultBoard(user) {
        const personal = this.boards$.getValue().find(el => el.id < 0);
        this.boardSelected$.next(personal);
        localStorage.setItem('taskManagerSelectedBoard' + user.id, JSON.stringify(personal));
    }

    setBoards(boards) {
        this.boards$.next(boards);
    }

    getBoardColor(colorName) {
        if (colorName?.startsWith('#')) {
            return colorName;
        }

        const st = tagsMapping.find(el => el.name === colorName);
        return st ? st.color : TagsColor.GREY;
    }

    checkMembershipOfBoard(board, user) {
        if (!board || !user) {
            return false;
        }

        const boards = this.boards$.getValue();
        if (!boards) {
            return false;
        }

        let boardSelected = boards.find(el => el.id === board.id);
        if (!boardSelected) {
            boardSelected = board;
        }
        if (boardSelected.id === -user.id) {
            return true;
        }

        if (!boardSelected.members) {
            return false;
        }

        return boardSelected.members.some(el => +el.id === +user.id);
    }

    getBoardName(board, user) {
        if (board.id < 0) {
            return board.name;
        }

        const isMember = this.checkMembershipOfBoard(board, user);

        if (isMember || !board.sharedBoardTitle) {
            return board.name;
        }

        if (!isMember && board.sharedBoard && board.sharedBoardTitle) {
            return board.sharedBoardTitle;
        }

        if (!isMember && board.sharedBoardTitle) {
            return board.sharedBoardTitle;
        }

        if (!isMember && board.name) {
            return board.name;
        }
    }

    getBoardBasePerformers(board) {
        return board.members?.filter(el => {
            if (el.onBoardDefault && el.onBoardDefault === 'performer') {
                return el;
            }
        });
    }

    getBoardBaseApprovers(board) {
        return board.members?.filter(el => {
            if (el.onBoardDefault && el.onBoardDefault === 'approver') {
                return el;
            }
        });
    }

    getBoardBaseObservers(board) {
        return board.members?.filter(el => {
            if (el.onBoardDefault && el.onBoardDefault === 'observer') {
                return el;
            }
        });
    }

    getBoardBaseResponsible(board) {
        return board.members.find(el => {
            if (el.onBoardDefault && el.onBoardDefault === 'responsible') {
                return el;
            }
        });
    }

    getBoardBaseTags(board) {
        return board.tags.filter(el => {
            if (el.on_board_default) {
                return el;
            }
        });
    }

    getParams(filter: Filter, matrixFlag = false, calendarFlag = false) {
        const isKanban = this.tableView$.value === 'kanban';
        let params = `?role=${filter['role']}`;

        if (this.settingsDone$.value) {
            if ((this.settingsDone$.value.isDonePlaningHide && VIEW[this.tableView$.value] === 'planing') ||
                (this.settingsDone$.value.isDoneHierarchyHide && VIEW[this.tableView$.value] === 'hierarchy') ||
                (this.settingsDone$.value.isDoneKanbanHide && VIEW[this.tableView$.value] === 'column') ||
                (this.settingsDone$.value.isDoneTableHide && VIEW[this.tableView$.value] === 'plan') ||
                (this.settingsDone$.value.isDoneGanttHide && VIEW[this.tableView$.value] === 'gantt')) {
                params += `&completed=false`;
            }
        }

        if (filter.fullHier) {
            params += `&full_hier=${filter['fullHier']}`;
        }

        if (filter.isResponsible) {
            params += `&isResponsible=true`;
        }

        if (filter.noticeable) {
            params += `&noticeable=${filter['noticeable']}`;
        }

        if (filter.importance) {
            params += `&flag_importance_local=${filter['importance']}`;
        }

        if (filter.importancePriority && filter.importancePriority.length) {
            filter.importancePriority.forEach(el => {
                params += `&importance[]=${el}`;
            });
        }

        if (filter.board) {
            const boardId = filter.board;
            params += `&b[]=${boardId}`;
        }

        if (filter.excludedStatuses) {
            filter.excludedStatuses.map(es => {
                params += `&excluded_statuses[]=${es}`;
            });
        }

        if (filter.tags) {
            filter.tags.forEach(el => {
                params += `&t[]=${el}`;
            });
        }

        if (filter.statuses && !isKanban) {
            filter.statuses.forEach(el => {
                params += `&statuses[]=${el}`;
            });
        }

        if (filter.groups) {
            filter.groups.forEach(el => {
                params += `&g[]=${el}`;
            });
        }

        if (filter.sprints) {
            filter.sprints.forEach(el => {
                params += `&sprint[]=${el}`;
            });
        }

        if (filter.performers) {
            filter.performers.forEach(el => {
                params += `&performer[]=${el}`;
            });
        }

        if (filter.observers) {
            filter.observers.forEach(el => {
                params += `&observer[]=${el}`;
            });
        }

        if (filter.authors) {
            filter.authors.forEach(el => {
                params += `&author[]=${el}`;
            });
        }

        if (filter.done) {
            params += `&done=${filter['done']}`;
        }

        if (filter.search.length) {
            params += `&search=${filter['search']}`;
        }

        if (filter.dateFrom) {
            params += `&date_expiration_from=${filter['dateFrom'].toJSON()}`;
        }

        if (filter.dateTo) {
            params += `&date_expiration_to=${filter['dateTo'].toJSON()}`;
        }

        if (filter.considerStartDate) {
            params += `&consider_start_date=${filter['considerStartDate']}`;
        }

        if (!matrixFlag) {
            if (!calendarFlag) {
                params += `&view=${VIEW[this.tableView$.value]}`;
            }
        } else {
            // matrix
            if (!filter.board) {
                params += '&all=true';
            }

            params += '&exclude_matrix_user=' + filter.performers[0];
        }

        return params;
    }
}

export function isOwner(board, user) {
    if (board) {
        const member = board.members?.find(el => el.id.toString() === user.id.toString());
        if (!member || !member.boardRole) {
            return false;
        }
        return member ? member.boardRole.toUpperCase() === BoardRole.OWNER : false;
    }

    return false;

}

export function isEditor(board, user) {
    if (board) {
        const member = board.members.find(el => el.id.toString() === user.id.toString());
        if (!member || !member.boardRole) {
            return false;
        }
        return member ? member.boardRole.toUpperCase() === BoardRole.EDITOR : false;
    }

    return false;
}

export function isMember(board, user) {
    if (board) {
        const member = board.members.find(el => el.id.toString() === user.id.toString());
        return !!member;
    }

    return false;

}

