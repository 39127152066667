import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UserShort} from '../../../../../models/user';
import {ConfigBoardsService} from '../../../services/config-boards.service';
import {AuthService} from '../../../../../services/auth.service';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {Matrix} from '../../../../phonebook/models/matrix/matrix';
import {ConfirmSmartComponent} from '../../../../shared/components/confirm-smart/confirm-smart.component';
import {takeLast, takeUntil} from 'rxjs/operators';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatrixUser} from '../../../../phonebook/models/matrix/matrix-subdivision';
import moment from 'moment';
import lodash from 'lodash';
import {ApiMotivationsService} from '../../../../phonebook/services/api-motivations.service';
import {Subject} from 'rxjs';
import {TasksService} from '../../../services/tasks.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {Task} from '../../../models/task';
import {MatrixService} from '../../../../phonebook/services/matrix.service';
import {formatDate} from '@angular/common';
import {StorageService} from '../../../../../services/storage.service';

@ Component({
    selector: 'app-members-item',
    templateUrl: './members-item.component.html',
    styleUrls: ['./members-item.component.scss']
})
export class MembersItemComponent implements OnInit, OnDestroy {
    @Output() onUserClick = new EventEmitter<UserShort>();
    @Output() onUserEditClick = new EventEmitter<any>();
    @Input() public user: UserShort = null;
    @Input() public shortView = false;
    @Input() public filtersMode = false;
    @Input() public action: string = null;
    @Input() public nameMembers = false;
    @Input() public hoverName = false;
    @Input() public isCanEdit = false;
    @Input() public isCanDelete = true;
    //** для обозначения участников непрочитанной задачи */
    @Input() public performer = false;
    @Input() public responsible = false;
    @Input() public showPlaningStats = false;
    @Input() public date = null;
    @Input() public total = null;
    @Input() public pending = false;

    @Input() public deniedUsers = [];
    @Input() taskId = null;

    private  destroyed = new Subject();

    public top: 0;
    public left = 0;

    public userId = null;
    isOpened = false;
    isOpen = false;
    public overlayOrigin: CdkOverlayOrigin;

    m = new Matrix();

    @Input() public availableMatrices: MatrixUser;
    @Input() task: Task;
    @Input() showAddToMatrixBtn = false;

    get smartTaskInfo() {
        return this.availableMatrices?.matrix?.smartTask;
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.complete();
    }

    get isSmartTask() {
        return !!this.availableMatrices?.matrix?.smartTask;
    }

    get isSmartTaskCanEdit() {
        return !!this.availableMatrices?.matrix?.smartTask && this.availableMatrices?.canEdit;
    }

    get tooltip() {
        return this.availableMatrices?.tooltip;
    }

    get canAddToMatrix() {
        return this.availableMatrices?.canAddToMatrix;
    }

    constructor(
        public config: ConfigBoardsService,
        private auth: AuthService,
        private dialog: MatDialog,
        private apiMatrix: ApiMotivationsService,
        private taskService: TasksService,
        private notiServ: GlobalNotificationCenterService,
        private matrixService: MatrixService,
        private storage: StorageService
    ) {
    }

    getColor() {
        if (this.user.stats.total > this.user.stats.plan) {
            return '_over'
        }

        const percents = !this.user.stats.plan ? 0 : Math.round(100 / this.user.stats.plan * this.user.stats.total);
        if (percents < 60) {
            return '_red'
        }

        if (percents > 59 && percents < 80) {
            return '_yellow'
        }

        if (percents > 79 && percents < 101) {
            return '_green'
        }
    }

    onClick() {
        if (this.action) {
            this.onUserClick.emit(this.user);
        }
    }

    onEditClick(evt) {
        evt.stopPropagation();

        if (this.isCanEdit) {
            this.onUserEditClick.emit(evt);
        }
    }

    get overdoze() {
        return this.user.stats.total > this.user.stats.plan;
    }

    onHover(evt) {
        this.top = evt.clientY;
        this.left = evt.clientX;
    }

    get isStatsVisible() {
        return this.user.stats && this.showPlaningStats && !isNaN(this.user.stats.plan) && !isNaN(this.user.stats.total)
    }

    ngOnInit() {
        this.userId = this.auth.currentUser$.value.id;
    }

    showPopup(trigger: CdkOverlayOrigin) {
        this.overlayOrigin = trigger;
        this.isOpen = true;
    }

    hidePopup() {
        this.isOpen = false;
    }

    canBeDeleted() {
        return !this.user['disabled'] && !this.pending;
    }

    canBeEdit() {
        return !this.user['disabled'] && !this.pending;
    }

    onAddToMatrix() {
        if (!this.canAddToMatrix) {
            return;
        }

        if (!this.availableMatrices.matrix && this.availableMatrices.motivation) {
            this.createAddToMatrixDialog();
            return;
        }

        if (this.availableMatrices.matrix) {
            this.addToMatrixDialog();
            return;
        }
    }

    getUserPlaningPoints(points) {
        if (this.task.assessmentType !== 'hour') {
            return points
        } else {
            return this.generateTimeFormat(points);
        }
    }

    generateTimeFormat(points) {
        let m = points % 60;
        let h = (points - m) / 60;
        let str = ''
        let min = ''

        min = m < 10 && h > 0 ? '0' + m : '' + m;

        str = (h ? h + ' ч ' : '') + (m ? min + ' мин' : '');

        return str;
    }

    addToMatrixDialog() {
        let name = this.user.shortestName;

        let dialogData;

        const date = lodash.upperFirst(moment(this.availableMatrices.matrix.start).format('MMMM YYYY'));

        if (this.availableMatrices.matrix.approveFact || this.availableMatrices.matrix.approvePlan) {
            dialogData = {
                title: `Матрица сотрудника ${name} уже согласована, вы уверены, что хотите добавить задачу в матрицу?`,
                description: `Задача добавится в матрицу за ${date}, матрица должна быть согласована повторно`,
                buttons: [
                    {
                        color: '_grey',
                        name: 'Отменить',
                        action: 'exit',
                        autofocus: false
                    },
                    {
                        color: '_blue',
                        name: 'Добавить',
                        action: 'save',
                        autofocus: true,
                    }]

            };
        } else {
            dialogData = {
                title: `Вы уверены, что хотите добавить задачу в матрицу сотруднику ${name}?`,
                description: `Задача добавится в матрицу за ${date}`,
                buttons: [
                    {
                        color: '_grey',
                        name: 'Отменить',
                        action: 'exit',
                        autofocus: false
                    },
                    {
                        color: '_blue',
                        name: 'Добавить',
                        action: 'save',
                        autofocus: true,
                    }]

            };
        }

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData,
            maxWidth: 500,
            restoreFocus: false,
            autoFocus: false,
        });

        dialogRef.afterClosed()
            .pipe(takeLast(1))
            .subscribe(result => {
                if (result === 'save') {
                    this.addTaskToMatrix(this.taskId, this.availableMatrices.matrix.id);
                } else {
                    //
                }
            });
    }

    addTaskToMatrix(taskId, matrixId) {
        const data = {taskId, matrixId}
        this.taskService.addTaskToMatrix$.next(data)
    }

    createMatrix(dt) {
        this.apiMatrix.createMatrix(+this.user.id, this.availableMatrices.motivation, dt).pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                this.addTaskToMatrix(this.taskId, resp.id);
            }, error => {
                this.notiServ.handleFullError(error);
            });
    }

    createAddToMatrixDialog() {
        let name = this.user.shortestName;

        const dt = moment.utc(this.task.dateExpiration).startOf('month');
        const date = lodash.upperFirst(dt.format('MMMM YYYY'));

        const dialogData = {
            title: `По действующей мотивации сотрудника не сформирована матрица. Сформировать матрицу и добавить в нее задачу?`,
            description: `Задача добавится в матрицу за ${date}`,
            buttons: [
                {
                    color: '_grey',
                    name: 'Отменить',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Добавить',
                    action: 'save',
                    autofocus: true,
                }]

        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData,
            maxWidth: 500,
            restoreFocus: false,
            autoFocus: false,
        });

        dialogRef.afterClosed()
            .pipe(takeLast(1))
            .subscribe(result => {
                if (result === 'save') {
                    this.createMatrix(dt);
                } else {
                    //
                }
            });
    }

    amIApprover() {
        return this.task.approvers.find(c => +c.id === +this.auth.auth.id)
    }

    itIsMe() {
        return +this.user.id === +this.auth.auth.id;
    }

    isDetailsVisible() {
        if (this.amIApprover()) {
            return true;
        }

        if (this.itIsMe())
        return false;
    }

    getMatrixLink() {
        if (+this.auth.auth.id === +this.user.id) {
            return '/cabinet/matrix/self-matrix/cabinet-matrix/' + this.user?.id + '/' + this.availableMatrices.matrix?.motivation?.user_motivation_id + '?date=' + formatDate(this.availableMatrices.matrix.start, 'YYYY-MM', 'en');
        }

        if (!this.availableMatrices ||
            !this.availableMatrices[this.user.id] ||
            !this.availableMatrices[this.user.id].canEdit) {
            return '';
        }

        if (!this.availableMatrices.matrix.coordinators?.find(u => +u.user?.id === +this.auth.auth.id)
            || this.matrixService.isAdmin$.value
        ) {
            return this.config.MATRIX_URL + this.user?.id + '/' + this.availableMatrices.matrix?.motivation?.user_motivation_id + '?date=' + formatDate(this.availableMatrices.matrix.start, 'YYYY-MM', 'en');
        }

        return undefined;
    }
}

