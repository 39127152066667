import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {first, map, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {defer, EMPTY, firstValueFrom, from, Observable, of, Subject, Subscription} from 'rxjs';
import {
    AccrualTypeMapping,
    EmploymentTypeEnum,
    EmploymentTypeMapping,
    MotivationStatusEnum,
    MotivationStatusMapping,
    RoleTypeEnum,
    UsersMotivation
} from '../../../phonebook/models/motivations/users-motivation';
import * as moment from 'moment/moment';
import {JSON} from 'ta-json';
import {File} from '../../../../models/file';
import {MotivationsUserShort} from '../../../phonebook/models/motivations/motivations-user-short';
import {NavService} from '../../../phonebook/services/nav.service';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AuthService} from '../../../../services/auth.service';
import {ApiPhonebookService} from '../../../phonebook/services/api-phonebook.service';
import {MotivationsService} from '../../../phonebook/services/motivations.service';
import {TasksService} from '../../../task-manager/services/tasks.service';
import {MatrixService} from '../../../phonebook/services/matrix.service';
import {ApiMotivationsService} from '../../../phonebook/services/api-motivations.service';
import {dateFormatShort, debug, defaultDateMask} from '../../../../utils/commons';
import {MotivationsCustomField, TypeEnum, TypesCustomField} from '../../../phonebook/models/motivations/motivations-custom-field';
import {MotivationsSettings} from '../../../phonebook/models/motivations/motivations-settings';
import {Link} from '../../../../models/link';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Motivation} from '../../../phonebook/models/motivations/motivation';
import {ConfirmSmartComponent} from '../confirm-smart/confirm-smart.component';
import {StorageService} from '../../../../services/storage.service';
import {formatDate} from '@angular/common';
import { UpdateMotivationEvent } from 'src/app/modules/phonebook/components/motivations/card-overlay/card-overlay.component';
import {ComponentCanDeactivate} from '../../../calendar/utils/pending-changes.guard';
import {Rights} from '../../../../models/user';

export enum MotivationType {
    Constant = 'CONSTANT',
    Bonus = 'BONUS',
}

export enum MotivationPeriodicity {
    Monthly = 'MONTHLY',
    OnceInTwoWeeks = 'ONCE_IN_TWO_WEEKS',
    OneTime = 'ONE_TIME',
    OnceInQuarter = 'ONCE_IN_QUARTER',
    OnceInHalfYear = 'ONCE_IN_HALF_YEAR',
    OnceInYear = 'ONCE_IN_YEAR',
}

export const PeriodicityMapping = Object.freeze([
    {
        value: MotivationPeriodicity.Monthly,
        name: 'Раз в месяц',
        adj: 'Ежемесячная',
    },
    {
        value: MotivationPeriodicity.OnceInTwoWeeks,
        name: 'Раз в две недели',
        adj: 'Двухнедельная',

    },
    {
        value: MotivationPeriodicity.OneTime,
        name: 'Единоразово',
        adj: 'Разовая',
    },
    {
        value: MotivationPeriodicity.OnceInHalfYear,
        name: 'Раз в полгода',
        adj: 'Полугодовая',
    },
    {
        value: MotivationPeriodicity.OnceInYear,
        name: 'Раз в год',
        adj: 'Годовая',
    },
    {
        value: MotivationPeriodicity.OnceInQuarter,
        name: 'Раз в квартал',
        adj: 'Квартальная',
    },
]);

export enum AccuralType {
    PerHour = 'PER_HOUR',
    PerMonth = 'PER_MONTH',
}

@Component({
    selector: 'app-motiv-card',
    templateUrl: './motiv-card.component.html',
    styleUrls: ['./motiv-card.component.scss']
})
export class MotivCardComponent implements OnInit, OnDestroy, OnChanges, ComponentCanDeactivate {
    @Input() chatId = null;
    @Input() chatRoomsFlag = false;
    @Output() onChatClose = new EventEmitter<null>();
    @Input() id;
    @Input() isNew = false;
    @Input() emp: UsersMotivation;
    @Input() user;
    @Input() showEmpBox = false;
    @Output() close = new EventEmitter<null>();
    @Output() updateList = new EventEmitter<null>();
    @Output() updateMotivation= new EventEmitter<UpdateMotivationEvent>();
    @Output() editing = new EventEmitter<boolean>();
    @Output() openFullCard = new EventEmitter();
    @Input() withoutUser = true;
    @Input() onlyReadonly = false;

    initialValues;
    getMotivationType = Motivation.getMotivationType;
    getMotivationDateRangeStr = Motivation.getMotivationDateRangeStr;
    isAdmin = false;
    public maskConfig = defaultDateMask();
    @ViewChild('toFocus') toFocus: ElementRef;
    isChatOpen = false;
    userName = '';
    motivation: Motivation;
    userlist: MotivationsUserShort[] = [];
    MotivationType = MotivationType;
    AccuralType = AccuralType;
    MotivationPeriodicity = MotivationPeriodicity;
    public customFields: MotivationsCustomField[] = [];
    AccuralTypeMapping = AccrualTypeMapping;
    public inputTypes = TypesCustomField;
    changed = false;
    MotivationStatus = MotivationStatusEnum;
    public settings: MotivationsSettings = null;
    isDisabled = false;
    motivationFormGroup = null;
    showErrors = false;
    allowAddMotivations = true;
    employmentTypes = EmploymentTypeMapping;
    employmentTypesEnum = EmploymentTypeEnum;
    readonly = !this.isNew;
    isExpanded = false;

    protected readonly formatDate = formatDate;
    private employee: MotivationsUserShort;
    private PeriodicityMapping = PeriodicityMapping;
    private savedVal: string;
    private destroyed = new Subject<void>();
    readonly periodicityLegacyError = 'Постоянные мотивации должны иметь периодичность раз в месяц';

    constructor(
        private route: ActivatedRoute,
        private motivApi: ApiMotivationsService,
        private notiService: GlobalNotificationCenterService,
        private nav: NavService,
        private dialog: MatDialog,
        public auth: AuthService,
        private phonebookApi: ApiPhonebookService,
        private motivations: MotivationsService,
        private tasksService: TasksService,
        private el: ElementRef,
        private matrix: MatrixService,
        private cdr: ChangeDetectorRef,
        private storageService: StorageService,
    ) {
    }

    get hasUnreadMessages() {
        return false;
    }

    get usersMotivationId() {
        return this.motivation.empId;
    }

    get total(): number {
        let salary = 0;
        if (this.motivationFormGroup.controls['motivation_type'].value === MotivationType.Constant) {
            salary = +this.motivationFormGroup.controls['salary'].value || 0;
        }
        const variable = +this.motivationFormGroup.controls['variable_path'].value || 0;

        const additional = this.additionalPayments();

        return salary + variable + additional;
    }

    get totalPerHour(): number {
        const salary = +this.motivationFormGroup.controls['salary'].value || 0;
        const variable = +this.motivationFormGroup.controls['variable_path'].value || 0;
        return salary + variable;
    }

    get totalPerHourPeriod() {
        const t = this.totalPerHour;
        const h = +this.motivationFormGroup.controls['hours'].value || 0;

        const additional = this.additionalPayments();
        return t * h + additional;
    }

    get onlyAuthorApprover() {
        const author = this.motivation.creator;
        const me = this.auth.auth;

        if (me.id === author.id) {
            // i am author

            if (this.userlist.length === 1 && this.userlist.find(u => +u.id === +me.id)) {
                return true;
            }
        }

        return false;
    }

    get hasAccess() {
        return this.isAdmin || this.motivation.coordinators.find(c => +c.user.id === +this.auth.auth.id);
    }

    get isMine() {
        return +this.employee?.id === +this.auth.auth.id;
    }

    static setPseudoStatuses(auth): (Motivation) => Motivation {
        return (motivation) => {
            return motivation;
        };
    }

    async getUserCardById(user) {
        const u = this.storageService.getUser(user?.id);
        this.userName = u ? u.shortName : '';

        if (u) {
            this.user = u;
        }

        const subdivId = u?.subdivision?.id;
        this.allowAddMotivations = subdivId >= 0;

        const resp = await firstValueFrom(this.phonebookApi.getUserById(user?.id));
        this.user = resp.user;
        this.userName = resp.user.shortName;
    }

    onFilesChange(files: File[]) {
        this.motivation.files = files;
        this.changed = true;
    }

    onLinksChange(links: Link[]) {
        this.motivation.links = links;
        this.changed = true;
    }

    getSettings() {
        this.motivApi.getMotivationsSettings()
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.motivations.settings$.next(res['settings']);
                    this.motivations.rights$.next(res['rights']);
                }
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    ngOnInit(): void {
        this.init();

        this.getSettings();

        if (this.chatRoomsFlag) {
            this.openChat();
        }

        if (this.isNew) {
            this.readonly = false;
        }

        this.motivations.trySave.pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (resp) {
                    this.onSave();
                }
            });

        this.matrix.createdMatrixEvent$.pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (resp) {
                    if (resp['status'] === 'succes') {
                        this.notiService.handleSuccess('Матрицы сформированы');
                        this.matrix.createdMatrixEvent$.next(null);
                    } else {
                        this.notiService.handleError('Ошибка формирования матриц');
                    }
                }
            });

        this.motivations.settings$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.settings = res;

                    this.customFields = this.settings.customFieldsSettings.map(el => el.customField);

                    this.initCustomFieldsByMotivation();
                }
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
        this.motivations.motivationLoading$.next(false);
    }

    initCustomFieldsByMotivation() {
        if (this.customFields && this.motivationFormGroup) {
            this.customFields.forEach(el => {
                el['init'] = true;

                this.motivationFormGroup.addControl('cf' + el.id.toString(), new UntypedFormControl({
                    value: this.motivation.motivationsCustomFields.find(cf => cf.field.id === el.id)?.filling || '',
                    disabled: this.isDisabled
                }));
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.motivation || changes.id) {
            this.motivation = null;
            if (this.isNew) {
                this.readonly = false;
                this.chatId = null;
                this.isChatOpen = false;
            }
            this.init();
        }

        if (changes.chatId) {
            this.chatId = changes.chatId.currentValue;

            if (this.chatId) {
                this.isChatOpen = true;
            } else {
                this.isChatOpen = false;
            }
        }
    }

    async init(reset = false) {
        await this.getUserCardById(this.user);

        if (this.id) {
            this.getMotivationById(this.id, reset);
        } else {
            this.motivationFormGroup?.enable();
            this.motivation = new Motivation();
            this.motivation.status = MotivationStatusEnum.DRAFT;
            this.motivation.periodicity = MotivationPeriodicity.Monthly;
            this.motivation.accrualType = AccuralType.PerMonth;
            this.motivation.type = this.MotivationType.Constant;
            this.motivation.coordinators = [];
            this.motivation.salary = 0;
            this.motivation.variablePath = 0;
            this.motivation.hoursPerPeriod = 0;
            this.userlist = [];
            this.motivation.creator = this.auth.auth;
            this.isDisabled = false;


            // add author
            this.userlist.push(
                // @ts-ignore
                new MotivationsUserShort(this.auth.auth as UserShort, 'AUTHOR', false, false),
            );

            this.initFormGroup();
        }
    }

    initFormGroup() {
        this.motivationFormGroup = new UntypedFormGroup({
            motivation_type: new UntypedFormControl({
                value: this.motivation.type,
                disabled: this.isDisabled,
            }),
            has_matrix: new UntypedFormControl({
                value: this.motivation.hasMatrix,
                disabled: this.isDisabled,
            }),
            accrual_type: new UntypedFormControl({
                value: this.motivation.accrualType,
                disabled: this.isDisabled,
            }),
            date_start: new UntypedFormControl({
                value: this.motivation.dateStart,
                disabled: !(this.isAdmin || (this.canApprove() && !this.itsMe()) || this.isNew),
            }, [Validators.required]),
            date_finish: new UntypedFormControl({
                value: this.motivation.dateFinish,
                disabled: !(this.isAdmin || (this.canApprove() && !this.itsMe()) || this.isNew),
            }),
            is_probation: new UntypedFormControl({
                value: this.motivation.isProbation,
                disabled: this.isDisabled
            }),
            hours: new UntypedFormControl({
                value: this.motivation.hoursPerPeriod,
                disabled: this.isDisabled,
            }, this.motivation.accrualType === AccuralType.PerHour ? [Validators.required, Validators.min(1)] : []),
            salary: new UntypedFormControl({
                value: this.motivation.salary,
                disabled: this.isDisabled
            }, [Validators.min(1)]),
            variable_path: new UntypedFormControl({
                value: this.motivation.variablePath,
                disabled: this.isDisabled
            }, [Validators.min(1)]),
            periodicity: new UntypedFormControl({
                value: this.motivation.periodicity,
                disabled: this.isDisabled
            }),
            note: new UntypedFormControl({
                value: this.motivation.note,
                disabled: this.isDisabled
            }),
        });

        const salary = this.motivationFormGroup.controls['salary'];
        const variable = this.motivationFormGroup.controls['variable_path'];
        const motType = this.motivationFormGroup.controls['motivation_type'];
        const period = this.motivationFormGroup.controls['periodicity'];

        function complexPaymentValidation() {
            if (motType.value === MotivationType.Constant) {
                if ((!salary.value || salary.value < 1) && (!variable.value || variable.value < 1)) {
                    salary.setValidators([Validators.min(1)]);
                    variable.setValidators([Validators.min(1)]);
                    salary.setErrors({min: true});
                    variable.setErrors({min: true});
                    salary.markAsDirty();
                    variable.markAsDirty();
                } else {
                    salary.clearValidators();
                    variable.clearValidators();
                    salary.setValidators([]);
                    variable.setValidators([]);
                    salary.setErrors(null);
                    variable.setErrors(null);
                }
            } else {
                salary.clearValidators();
                variable.clearValidators();
                salary.setValidators([]);
                variable.setValidators([]);
                salary.setErrors(null);
                variable.setErrors(null);
                variable.setValidators([Validators.required, Validators.min(1)]);
            }
        }

        complexPaymentValidation();

        function checkPeriodicity() {
            if (motType.value === MotivationType.Constant) {
                if (period.value !== MotivationPeriodicity.Monthly
                    || period.value !== MotivationPeriodicity.OnceInTwoWeeks) {
                    period.setValue(MotivationPeriodicity.Monthly);
                }
            } else {
                if (period.value === MotivationPeriodicity.OnceInTwoWeeks) {
                    period.setValue(MotivationPeriodicity.Monthly);
                }
            }
        }

        motType.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                complexPaymentValidation();

                checkPeriodicity();
            });

        salary.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe((value) => {
                complexPaymentValidation();
            });

        variable.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe((value) => {
                complexPaymentValidation();
            });

        this.motivationFormGroup.controls['accrual_type'].valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (resp === AccuralType.PerHour) {
                    this.motivationFormGroup.controls['hours'].clearValidators();
                    this.motivationFormGroup.controls['hours'].setValidators([Validators.required, Validators.min(1)]);
                    this.motivationFormGroup.controls['hours'].updateValueAndValidity();
                } else {
                    this.motivationFormGroup.controls['hours'].clearValidators();
                    this.motivationFormGroup.controls['hours'].setValidators([]);
                    this.motivationFormGroup.controls['hours'].updateValueAndValidity();
                }
            });

        this.initCustomFieldsByMotivation();

        // clear saved value if changed
        this.motivationFormGroup.valueChanges.pipe(takeUntil(this.destroyed))
            .subscribe(changed => {
                this.savedVal = null;
            });

        if (!this.readonly) {
            this.getPermanentCoordinators();
        }

        this.initialValues = [];
        for (const key of Object.keys(this.motivationFormGroup.controls)) {
            this.initialValues[key] = this.motivationFormGroup.controls[key].value;
        }
    }

    cancel() {
        //this.close.emit();
        if (this.isNew) {
            this.closeMot();
        } else {
            this.init(true);
        }
    }

    closeMot() {
        this.close.emit();
    }


    submit() {
        const data = this.motivationFormGroup.getRawValue();

        data.motivations_custom_fields = [];
        data.motivations_custom_fields = this.customFields.map((el, index) => {
            const params = {
                id: this.motivation.id ? el.id : null,
                filling: this.motivationFormGroup.controls['cf' + el.id.toString()]?.value,
                field: JSON.serialize(el)
            };

            delete data['new_' + index];
            delete data[+el.id];
            return params;
        });

        if (this.isNew) {
            data.user_motivation_id = this.emp.id;
        }

        data.date_start = this.motivationFormGroup.controls['date_start'].value
            ? moment(this.motivationFormGroup.controls['date_start'].value).format('YYYY-MM-DD')
            : null;
        data.date_finish = this.motivationFormGroup.controls['date_finish'].value
            ? moment(this.motivationFormGroup.controls['date_finish'].value).format('YYYY-MM-DD')
            : null;

        data.status = this.isNew ? MotivationStatusEnum.DRAFT : this.motivation.status;

        let employee = this.getEmployee();
        if (!employee) {
            employee = this.employee;
        }
        data.permanent_coordinators = [+this.user.id];
        if (employee && !data.permanent_coordinators.includes(employee)) {
            data.permanent_coordinators.push(employee?.id);
        }

        const addCoords = this.userlist.map(u => u.id).filter(uid => !data.permanent_coordinators.includes(uid));
        data.additional_coordinators = addCoords;

        data.files = this.motivation.files ? JSON.serialize(this.motivation.files) : undefined;
        data.links = this.motivation.links ? JSON.serialize(this.motivation.links) : undefined;

        data.salary = data.salary || undefined;
        data.variable_path = data.variable_path || undefined;

        if (this.isNew) {
            this.motivApi.createMotivation(data)
                .pipe(takeUntil(this.destroyed))
                .subscribe(resp => {
                    this.motivations.motivationCreated$.next({
                        user: this.user,
                        emp: this.emp,
                        motivation: resp,
                    });

                    this.readonly = true;
                    this.nav.openMotivationById(this.user.id, this.emp.id, resp.id);
                }, err => {
                    this.notiService.handleFullError(err);
                });
        } else {
            this.motivApi.editMotivationById(data, this.motivation.id)
                .pipe(takeUntil(this.destroyed))
                .subscribe(resp => {
                    this.changed = false;
                    this.motivation = resp;
                    this.init();

                    this.motivations.motivationUpdated$.next({
                        user: this.user,
                        emp: this.emp,
                        motivation: resp,
                    });

                    this.readonly = true;
                    this.motivation = MotivCardComponent.setPseudoStatuses(this.auth)(this.motivation);

                }, err => {
                    this.notiService.handleFullError(err);
                });
        }

        return true;
    }

    onUsersChange($event) {
        this.changed = true;

        this.userlist = $event.map(u => {
            const isReaded = u.id === this.auth.auth.id;
            const role = this.motivation.creator.id === u.id ? 'AUTHOR' : 'COORDINATOR';
            return new MotivationsUserShort(u, role, false, !isReaded);
        });

        this.uniq();
    }

    getMotivationStatus(status: string) {
        const mot = MotivationStatusMapping.find(s => s.value === status);
        return mot?.name;
    }

    getMotivationStatusClass(status: string) {
        const mot = MotivationStatusMapping.find(s => s.value === status);
        return mot?.class;
    }

    onDeleteClick() {
        const dialogData = {
            title: 'Удалить мотивацию?'
            ,
            buttons: [
                {
                    color: '_grey',
                    name: 'Отмена',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Удалить',
                    action: 'delete',
                    autofocus: true
                }]

        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result === 'delete') {
                    this.deleteMotivation(this.motivation.id);
                }
            });
    }

    deleteMotivation(id) {
        this.motivApi.deleteMotivationById(id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.motivations.motivationRemoved$.next({
                    user: this.user,
                    emp: this.emp,
                    motivation: id,
                });

                this.closeMot();
                //this.nav.openUserMotivCardById(this.user?.id, this.emp?.id);
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    setApproversDisabled() {
        this.userlist.forEach(el => {
            if (el.role === 'EMPLOYEE') {
                el['disabled'] = true;
            }
        });
    }

    isValid() {
        return this.motivation.type && this.motivation.dateStart &&
            this.motivation.periodicity && this.emp.id && this.motivation.status && this.motivation.accrualType;
    }

    toApprove() {
        this.motivApi.sendToApprove(this.motivation.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (!resp['error']) {
                    this.motivation.status = MotivationStatusEnum.ON_APPROVE;

                    this.motivations.motivationUpdated$.next({
                        user: this.user,
                        emp: this.emp,
                        motivation: resp,
                    });
                }
            });
    }

    isAuthor() {
        return this.auth.auth.id === this.motivation.creator.id;
    }

    approve() {
        this.motivApi.approveMotivationById(this.motivation.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                this.motivation = resp;

                this.init();

                this.motivations.motivationUpdated$.next({
                    user: this.user,
                    emp: this.emp,
                    motivation: resp,
                });
            });
    }

    cancelApprove() {
        this.motivApi.cancelApproveMotivationById(this.motivation.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                this.motivation = resp;

                this.motivations.motivationUpdated$.next({
                    user: this.user,
                    emp: this.emp,
                    motivation: resp,
                });
            });
    }

    isApprovedByMe() {
        const me = this.motivation.coordinators.find(mc => parseInt(mc.user.id, 10) === this.auth.auth.id);

        return me?.approve;
    }

    canApprove() {
        const me = this.motivation.coordinators.find(mc => +mc.user.id === +this.auth.auth.id) || null;

        return me !== null;
    }

    getPermanentCoordinators() {
        let subds = null;
        if (Array.isArray(this.user?.subdivisions)) {
            subds = this.user.subdivision[0];
        }
        if (this.user?.subdivision) {
            subds = this.user.subdivision;
        }

        let employee = this.getEmployee();
        if (!employee) {
            employee = this.user;
        }
        if (!employee) {
            employee = this.employee;
        }

        this.motivApi.getSettings(subds?.id, employee?.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {

                if (resp?.coordinators && this.isNew) {
                    this.userlist.push(...resp.coordinators.map(
                        u => new MotivationsUserShort(u, 'COORDINATOR', false, true)
                    ));
                }

                if (resp?.executives && this.isNew) {
                    this.userlist.push(...resp.executives.map(
                        u => new MotivationsUserShort(u, 'COORDINATOR', false, true)
                    ));
                }

                this.uniq();
            }, error => {
                this.notiService.handleError(error);
            });
    }

    cancelMotivation() {
        this.motivApi.cancelMotivationById(this.motivation.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                this.motivation = resp;

                this.motivations.motivationUpdated$.next({
                    user: this.user,
                    emp: this.emp,
                    motivation: resp,
                });
            });
    }

    setReaded() {

        const me = this.motivation.coordinators.find(mc => +mc.user.id === +this.auth.auth.id) || null;

        if (this.motivation?.id && me && !me.read) {
            this.motivApi.readMotivationById(this.motivation.id)
                .pipe(takeUntil(this.destroyed))
                .subscribe(resp => {
                    const u = this.userlist.find(u => +u.id === +this.auth.auth.id);
                    if (u) {
                        u['isNotReaded'] = false;
                    }
                });
        }
    }

    clearDate() {
        this.motivationFormGroup.controls['date_start'].setValue('');
        this.motivationFormGroup.controls['date_finish'].setValue('');
    }

    iAmAuthor() {
        return +this.motivation.creator.id === +this.auth.auth.id;
    }

    getAccuralType(motivation: Motivation) {
        const res = this.AccuralTypeMapping.find(a => a.value === motivation.accrualType);

        return res ? res.name : '-';
    }

    getPeriodicity(motivation: Motivation) {
        const res = this.PeriodicityMapping.find(a => a.value === motivation.periodicity);

        return res ? res.name : '-';
    }

    itsMe() {
        const emp = this.motivation.coordinators.find(u => u.role === 'EMPLOYEE');

        if (emp) {
            return +emp?.user?.id === +this.auth.auth.id;
        } else {
            return false;
        }
    }

    getSummVariable() {
        return this.motivationFormGroup.controls['variable_path'].value * this.motivationFormGroup.controls['hours'].value || 0;
    }

    getSummSalary() {
        return this.motivationFormGroup.controls['salary'].value * this.motivationFormGroup.controls['hours'].value || 0;
    }

    getEmployee() {
        const employee = this.motivation.coordinators.find(u => u.role === 'EMPLOYEE');

        if (this.motivation.isApproved || this.motivation.coordinators.length === 1) {
            return new MotivationsUserShort(
                employee.user,
                RoleTypeEnum.EMPLOYEE,
                employee.approve,
                !employee.read,
            );
        } else {
            return null;
        }
    }

    async onChangeMotivation() {
        // get dirty values and skip confirm dialog if it's in the skiplist
        let skipConfirm = true;

        const controls = this.motivationFormGroup.controls;
        const keys = Object.keys(controls);
        const skipList = ['note', 'has_matrix', 'date_finish'];
        if (this.motivation.id) {
            for (const key of keys) {
                const currentControl = controls[key];

                let value = currentControl.value;
                let oldValue = this.initialValues[key];
                if (value instanceof Date) {
                    // hack for comparing dates without time
                    if (value && value.setHours) {
                        value.setHours(0, 0, 0, 0);
                        value = value.toDateString();
                    }

                    if (oldValue && oldValue.setHours) {
                        oldValue.setHours(0, 0, 0, 0);
                        oldValue = oldValue.toDateString();
                    }
                }

                if (value !== oldValue && !skipList.includes(key)) {
                    console.log('CHANGED:', key, value, oldValue);
                    skipConfirm = false;
                    break;
                }
            }
        }

        // if no one had approved then submit
        if (!this.motivation.coordinators.find(c => c.approve) || skipConfirm) {
            return this.submit();
        }

        // else show configm dialog
        const dialogForm = this.dialog.open(ConfirmSmartComponent, {
            data: {
                title: 'Кто-то уже согласовал, при сохранении изменений согласования будут отменены?',
                buttons: [
                    {
                        color: '_grey',
                        name: 'Отмена',
                        action: 'exit',
                        autofocus: false
                    },
                    {
                        color: '_blue',
                        name: 'Сохранить',
                        action: 'change',
                        autofocus: true
                    }
                ],
            }
        });

        const forceSave = await dialogForm.afterClosed().toPromise();

        if (forceSave === 'change') {
           return this.submit();
        }


        return false;
    }

    canChangeMotivation() {
        // if it's canceled you can't change
        if (this.motivation.status === this.MotivationStatus.CANCELED) {
            return false;
        }

        return true;
    }

    canSendToApprove() {
        // if it's diabled you can't change
        if (this.isDisabled) {
            return false;
        }

        if (this.isNew) {
            return false;
        }

        if (this.motivation.status === this.MotivationStatus.DRAFT) {
            return true;
        }

        return false;
    }

    canApproveOrCancel() {

        return (this.motivation.status === this.MotivationStatus.ON_APPROVE || this.motivation.status === this.MotivationStatus.NEED_APPROVE) && !this.itsMe() &&
            this.userlist.find(u => +u.id === +this.auth.auth.id) && !this.isApprovedByMe();
    }

    canCancelMotivation() {

        return this.motivation.status === this.MotivationStatus.APPROVED && !this.itsMe() && !this.isDisabled;
    }

    isMotivationChanged() {
        // if it's new or was changed - you can save changes
        if (this.isNew || this.motivationFormGroup.dirty || this.changed) {
            return true;
        }

        return false;
    }

    getCustomFieldValue(el: MotivationsCustomField) {
        const val = this.motivation.motivationsCustomFields.find(cf => cf.field.id === el.id)?.filling || null;

        if (val) {
            if (el.type === TypeEnum.INTEGER) {
                return val + '₽';
            } else {
                return val;
            }
        } else {
            return null;
        }
    }

    clearOnFocus($event: FocusEvent, formControlName?) {
        // const target = $event.target as HTMLInputElement;
        // this.savedVal = target.value;
        // target.value = '';
        //
        // if (formControlName) {
        //     this.motivationFormGroup.controls[formControlName].setValue('');
        // }
    }

    restoreUnchanged($event: FocusEvent) {
        const target = $event.target as HTMLInputElement;
        if (!target.value && this.savedVal) {
            //target.value = this.savedVal;
        }
    }

    isValidSalary() {
        if (this.motivationFormGroup.controls['motivation_type'].value === MotivationType.Constant) {
            if (+this.motivationFormGroup.controls['salary'].value <= 0 &&
                +this.motivationFormGroup.controls['variable_path'].value <= 0) {

                this.notiService.handleError('Мотивация не может быть без суммы');
                return false;
            } else {
                return true;
            }
        } else {
            return this.motivationFormGroup.controls['variable_path'].value >= 1;
        }
    }

    toApproveOnlyMe() {
        this.motivApi.sendToApprove(this.motivation.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (!resp['error']) {
                    this.motivation.status = MotivationStatusEnum.ON_APPROVE;
                    this.approve();
                }
            });
    }

    openChat() {
        this.isChatOpen = true;
    }

    toggleChat() {
        if (this.isChatOpen) {
            this.chatClose();
        } else {
            this.isChatOpen = true;
        }
    }

    validPeriodicity() {
        if (this.motivationFormGroup.controls['motivation_type'].value === MotivationType.Constant) {
            if (this.motivationFormGroup.controls['periodicity'].value !== MotivationPeriodicity.Monthly) {
                return false;
            }
        }

        return true;
    }

    onSave() {
        if (!this.validPeriodicity()) {
            this.motivationFormGroup.controls['periodicity'].setValue(MotivationPeriodicity.Monthly);
        }

        if (!this.motivationFormGroup.valid || !this.isValidSalary()) {
            this.showErrors = true;
            this.notiService.handleError('Все обязательные поля должны быть заполнены');
            return false;
        } else {
            this.showErrors = false;
            return this.onChangeMotivation();
        }
    }

    getVariable() {
        return this.motivation.variablePath || 0;
    }

    chatClose() {
        this.isChatOpen = false;
        this.chatId = null;
        this.onChatClose.emit();
    }

    goToApp() {
        this.nav.open(`/apps/phonebook/users/motivations/timetable/${this.employee?.id}/${this.usersMotivationId}/${this.id}`);
    }

    getDate() {
        moment.locale('ru');
        const start = moment(this.motivation.dateStart);

        const startYear = start.year();
        const nowYear = moment().year();


        let result = dateFormatShort(start) + ' ';

        if (nowYear !== startYear) {
            result += startYear + ' ';
        }

        result += '- ';


        const end = moment(this.motivation.dateStart);
        if (this.motivation.dateFinish) {
            result += dateFormatShort(this.motivation.dateFinish);
        }

        const endYear = end.year();

        if (nowYear !== endYear) {
            result += ' ' + endYear;
        }

        return result;
    }

    goToCabinet() {
        this.nav.open(`/cabinet/motivation/self/${this.motivation.id}`);
    }

    canEdit() {
        if (this.isAdmin) {
            return true;
        }

        if (this.itsMe()) {
            return false;
        }

        return this.isMeCoordinator();
    }

    allowEdit() {
        this.readonly = !this.readonly;
        this.editing.next(!this.readonly);
        this.cdr.detectChanges();
    }

    isFooterShown() {
        if (this.canChangeMotivation() && !this.readonly) {
            return true;
        }

        if ((this.motivation.status === this.MotivationStatus.FAMILIARIZE) && this.itsMe() && !this.isApprovedByMe()) {
            return true;
        }

        if (this.canSendToApprove() || this.canApproveOrCancel()) {
            return true;
        }

        return false;
    }

    getEmploymentType(emp: UsersMotivation) {
        const item = this.employmentTypes.find(e => e.value === emp.employmentType);

        return item?.name || 'Не указано';
    }

    openFull() {
        this.openFullCard.emit(true);
    }

    onHeaderClick() {
        this.isExpanded = !this.isExpanded;
    }

    isMeCoordinator() {
        return this.isAdmin ||
            this.userlist.findIndex(c =>
                c.role.toUpperCase() !== 'EMPLOYEE' &&
                +c.id === this.auth.auth.id
            ) !== -1;
    }

    private getMotivationById(id: number, reset = false) {
        this.motivations.motivationLoading$.next(true);

        this.motivApi.getMotivationById(id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                this.isDisabled = false;
                this.motivation = resp.payload;
                this.isAdmin = resp.rights.isAdministrator;

                this.userlist = this.motivation.coordinators.map(c => {
                        const cuser = c.user;
                        return new MotivationsUserShort(cuser, c.role, c.approve, !c.read);
                    }
                );

                // hide employee
                this.employee = this.userlist.find(u => u.role === 'EMPLOYEE');
                this.userlist = this.userlist.filter(u => u.role !== 'EMPLOYEE');

                if (this.isAdmin || (this.canApprove() && !this.itsMe())) {
                    this.isDisabled = false;
                } else {
                    this.isDisabled = true;
                }

                this.motivation = MotivCardComponent.setPseudoStatuses(this.auth)(this.motivation);

                if (this.motivation.status === MotivationStatusEnum.FINISHED) {
                    this.isDisabled = true;
                }

                this.setApproversDisabled();

                this.setReaded();

                this.initFormGroup();

                this.uniq();

                this.readonly = true;

                this.motivations.motivationLoading$.next(false);
                this.cdr.detectChanges();

            }, error => {
                this.motivations.motivationLoading$.next(false);
                this.notiService.handleFullError(error);
            });
    }

    private uniq() {
        const unique = (value, index, self) => self.map(x => x.id).indexOf(value.id) === index;

        this.userlist = this.userlist.filter(unique);
    }

    private additionalPayments() {
        let sum = 0;
        this.customFields.filter(cf => cf.type === TypeEnum.INTEGER)
            .map(cf => {
                const valStr = this.motivationFormGroup.controls['cf' + cf.id]?.value;
                const val = parseInt(valStr, 10) || 0;
                sum += val;
            });

        return sum;
    }

    async confirmExitDialog() {
        const data = {
            title: 'Сохранить изменения?',
            buttons: [
                {
                    color: '_grey',
                    name: 'Нет',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Да',
                    action: 'save',
                    autofocus: true
                }]
        };

        const dref = this.dialog.open(ConfirmSmartComponent, {
            minWidth: '500px',
            maxWidth: '500px',
            data,
        });

        const result = await firstValueFrom(dref.afterClosed());

        if (result == 'save') {
            this.onSave();
            return false;
        } else {
            return true;
        }
    }

    canDeactivate(): boolean | Observable<boolean> {
        if (this.readonly || !this.isMotivationChanged()) {
            return true;
        }

        return from(this.confirmExitDialog());
    }

    isDeletable() {
        if (this.motivation.status === MotivationStatusEnum.DRAFT) {
            return true;
        }

        if (this.motivation.status === MotivationStatusEnum.APPROVED || this.motivation.status === MotivationStatusEnum.NEED_APPROVE) {
            if (moment(this.motivation.dateStart).isAfter(moment())) {
                return true;
            }
        }

        return false;
    }
}
