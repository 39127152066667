<div class="person" *ngIf="user" (click)="onClick()" [ngClass]="{'_active': action, '_short': shortView, '_small': size === 'SMALL',
    '_disabled': user['disabled'] || disabled}">

    <app-user-avatar class="person__img"
                     *ngIf="user.id > 0"
                     [user]=user
                     #tooltip="matTooltip"
                     (click)="openTooltip(tooltip)"
                     [matTooltip]="tooltipMessage">
    </app-user-avatar>

    <div class="icon-container _responsive" *ngIf="isResponsible">
        <mat-icon class="responsive"
                  svgIcon="radius_star-border"></mat-icon>
    </div>

    <span class="person__title" *ngIf="shortView && showName && user.firstName">
        {{ user.firstName }}
    </span>

    <span class="person__title" *ngIf="shortView && showName && user['first_name']">
        {{ user['first_name']}}
    </span>

    <span class="person__title txt" *ngIf="!shortView">
            {{ user.shortName }}
        <ng-container *ngIf="action && action === 'add'">
            <mat-icon class="icon add-icon">add</mat-icon>
        </ng-container>
        <ng-container *ngIf="action && action === 'delete'">
            <mat-icon class="icon add-icon">close</mat-icon>
        </ng-container>
    </span>

    <div class="delete" *ngIf="!user['disabled'] && !disabled" [matTooltip]="user.shortName">
        <mat-icon class="icon">close</mat-icon>
    </div>
</div>
