import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CheckedUser} from '../../../../models/user';

@Component({
  selector: 'app-select-user-dialog-checkbox',
  templateUrl: './select-user-dialog-checkbox.component.html',
  styleUrls: ['./select-user-dialog-checkbox.component.scss']
})
export class SelectUserDialogCheckboxComponent {
    users: CheckedUser[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<any>,
    ) {
        this.users = data.users.map(u => {
            return new CheckedUser(u, data.defaultChecked === undefined ? data.checked : data.defaultChecked);
        });
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.users);
    }

    saveNo() {
        this.dialogRef.close([]);
    }

    isDisabled() {
        if (!this.data.required) {
            return false;
        }

        return  this.users.find(u => u.checked) === undefined;
    }
}
