import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {File, FileType, getFileExtensionWidget} from '../../../../models/file';
import {combineLatest, Subject} from 'rxjs';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {ApiBoardsService} from '../../../task-manager/services/api-boards.service';
import {TasksService} from '../../../task-manager/services/tasks.service';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {FileuploadFormComponent} from './fileupload-form/fileupload-form.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PhotoGalleryService} from '../../../../services/photo-gallery.service';
import {saveAs as importedSaveAs} from 'file-saver';
import {Link} from '../../../../models/link';
import {ConfirmSmartComponent} from '../confirm-smart/confirm-smart.component';
import {LinkEditFormComponent} from './link-edit-form/link-edit-form.component';
import {FileEditFormComponent} from './file-edit-form/file-edit-form.component';
import {SharedService} from '../../services/shared.service';
import {ChatsService} from '../../../../services/chats.service';

@Component({
    selector: 'app-fileupload',
    templateUrl: './fileupload.component.html',
    styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit, OnDestroy {
    @Input() public disabled = false;

    @Input() public additionalText = null;
    // Массив картинок
    @Input() public files: File[] = [];
    // Массив картинок
    @Input() public links: Link[] = [];
    /** Условие для работы с файлами у архивных и сделанных задач */
    @Input() public uploadCondition = false;
    @Input() public entityId = 'boards';
    // Смена обложки
    @Input() public cover: number = null;
    @Input() public taskId = null;
    @Input() public largeTask = false;
    @Input() canSetCover = true;

    @Output() uploadDialogClose = new EventEmitter<boolean>();
    @Output() public readonly setCover: EventEmitter<any> = new EventEmitter();
    // Триггер изменения изображений
    @Output() public readonly filesChange: EventEmitter<any> = new EventEmitter();
    // Триггер изменения ссылок
    @Output() public readonly linksChange: EventEmitter<any> = new EventEmitter();

    @Input() gDocOutlet: string;

    // Новые файлы добалвнные но не сохраненные
    public newFiles: File[] = [];
    @Input() public chatRoomsFlag = false;
    // Файл отправленный на удаление
    public fileToDelete: File = null;
    // Открыта ли раскривушка компонента
    public isOpened = true;
    public dialogForm = null;
    /** ссылка на удаление */
    public linkToDelete = null;
    readonly PDF_MIME = 'application/pdf';
    readonly PDF_FORMAT = '.pdf';
    public loadedFiles = [];
    public CONFIRM = {
        link: 'Удалить ссылку?',
        file: 'Удалить файл?'
    };
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    @Input() showIcon = true;

    constructor(
        private notiService: GlobalNotificationCenterService,
        private api: ApiBoardsService,
        private tasksService: TasksService,
        private config: ConfigBoardsService,
        private dialog: MatDialog,
        private galleryService: PhotoGalleryService,
        private sharedService: SharedService,
        private chatService: ChatsService
    ) {
    }

    get isEmpty() {
        if (!this.files && !this.links) {
            return false;
        }
        return (!this.files.length && !this.links.length);
    }

    ngOnInit() {
        combineLatest(this.tasksService.taskOnSubmit$, this.tasksService.taskHasChanges$)
            .pipe(takeUntil(this.destroyed))
            .subscribe(([submit, hasChanges]) => {
                if (submit && !hasChanges) {
                    this.clearNewFiles();
                }
            });
    }

    public setFilesFromPaste(items: any): void {
        let blob = null;

        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                blob = item.getAsFile();
            }
        }

        // load image if there is a pasted image
        if (blob !== null) {
            const uploadData = new FormData();
            uploadData.append('upload', blob);
            uploadData.append('entity_id', this.entityId);
            this.uploadFile(uploadData);
            if (this.dialogForm) {
                this.dialogForm.close();
            }
        }
    }

    openAddForm(evt) {
        evt.stopPropagation();
        this.isOpened = true;

        this.dialogForm = this.dialog.open(FileuploadFormComponent, {
            width: '902px',
            height: '641px',
            disableClose: this.sharedService.openTaskLearning$.value
        });

        const subscribeDialogFiles = this.dialogForm.componentInstance.filesChange.subscribe((files) => {
            if (files) {
                Array.prototype.push.apply(this.files, files);
                this.filesChange.emit(this.files);
            }
        });
        const subscribeDialogLink = this.dialogForm.componentInstance.linkAdd.subscribe((links) => {
            if (links) {
                Array.prototype.push.apply(this.links, links);
                this.linksChange.next(this.links);
            }
        });

        this.dialogForm.afterClosed().subscribe(result => {
            subscribeDialogFiles.unsubscribe();
            subscribeDialogLink.unsubscribe();

            this.uploadDialogClose.emit(result);
        });
    }

    openEditFormFile(evt, item) {
        evt.preventDefault();
        evt.stopPropagation();

        this.dialogForm = this.dialog.open(FileEditFormComponent, {
            width: '500px',
            data: {
                name: item.fileName,
            },
        });

        this.dialogForm.afterClosed().subscribe((result) => {
            if (result) {
                item.fileName = result.name;
            }

            this.filesChange.next(this.files);
        });
    }

    openEditFormLink(evt, item) {
        evt.preventDefault();
        evt.stopPropagation();

        this.dialogForm = this.dialog.open(LinkEditFormComponent, {
            width: '500px',
            height: '316px',
            data: {
                name: item.name,
                url: item.url,
            },
        });

        this.dialogForm.afterClosed().subscribe((result) => {
            if (result) {
                item.name = result.name;
                item.url = result.url;
            }

            this.linksChange.next(this.links);
        });
    }

    onRemoveFileClick(file: File, evt) {
        evt.stopPropagation();
        this.fileToDelete = file;
        this.confirmDelete('file');
    }

    onRemoveLinkClick(link: File, index) {
        this.linkToDelete = this.links.filter(el => el !== this.links[index]);

        this.confirmDelete('link');
    }

    confirmDelete(type) {
        const dialogData = {
            title: this.CONFIRM[type],
            buttons: [
                {
                    color: '_grey',
                    name: 'Отмена',
                    action: '',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Удалить',
                    action: 'save',
                    autofocus: true
                }]
        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result === 'save' && type === 'link') {
                    this.linksChange.next(this.linkToDelete);
                }

                if (result === 'save' && type === 'file') {
                    this.deleteFile(this.fileToDelete.id);
                }
            });
    }

    uploadFile(uploadData) {
        this.api.uploadFile(uploadData)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                const file = JSON.deserialize<File>(res, File);
                this.files.push(file);
                this.newFiles.push(file);
                this.filesChange.emit(this.files);
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    deleteFile(deleteItemId) {
        if (deleteItemId > 0) {
            this.filterFilesById(deleteItemId);
            return false;
        } else {
            this.filterFilesById(deleteItemId);
        }
    }

    filterFilesById(id) {
        this.files = this.files.filter(el => el.id !== id);
        this.newFiles = this.newFiles.filter(el => el.id !== id);
        this.filesChange.emit(this.files);
    }

    openImage(image) {
        if (!this.uploadCondition) {
            // if (this.disabled) {
            //     return;
            // }
        }
        const onlyPhotos = this.files.filter(el => this.isImage(el));

        this.galleryService.photos$.next(onlyPhotos);
        this.galleryService.currentPhotoId$.next(image.id);
        this.galleryService.entityId$.next(image.entityId);
    }

    getFileUrlPdf(file) {
        if (this.isPdf(file)) {
            return `${this.config.fileStorageURL}files/${file.entityId}/${file.id}/${file.name}`;
        }

        return '';
    }

    isPdf(file) {
        return file.mime === this.PDF_MIME && file.fileName.search(this.PDF_FORMAT) > 0;
    }

    openInGDocOutlet(url) {
        if (!this.gDocOutlet) {
            this.tasksService.gDocUrl$.next(url);
        } else {
            this.tasksService.gDocUrlForChat$.next(url);
        }
    }

    downloadFile(file) {
        if (this.isLoaded(file)) {
            return false;
        }

        if (!this.uploadCondition) {
            // if (this.disabled) {
            //     return;
            // }
        }
        if (this.isPdf(file)) {
            this.openInGDocOutlet(`${this.config.fileStorageURL}files/boards/${file.name}`);
            // window.open(`${this.config.fileStorageURL}files/${file.entityId}/${file.id}/${file.name}`, '_blank');
            return false;
        }

        this.loadedFiles.push(file.id);
        this.api.getFile(file.entityId, file.id, file.name)
            .subscribe(res => {
                    const blob = new Blob([res], {type: file.mime});
                    importedSaveAs(blob, file.fileName);
                    this.loadedFiles = this.loadedFiles.filter(el => el !== file.id);
                }
            );
    }

    isLoaded(file) {
        return this.loadedFiles.some(el => el === file.id);
    }

    public clearNewFiles() {
        this.newFiles = [];
    }

    public deleteNewFiles() {
        this.newFiles.forEach(el => {
            this.deleteFile(el.id);
        });
    }

    onCoverClick(item, evt) {
        evt.stopPropagation();
        if (item.id === this.cover) {
            this.setCover.emit(null);
            this.cover = null;
        } else {
            this.setCover.emit(item.id);
            this.cover = item.id;
        }

    }

    isFile(item) {
        if (item.mime.search('tiff') >= 0) {
            return true;
        }
        return item.type === FileType.FILE;
    }

    isImage(item) {
        if (item.mime.search('tiff') >= 0) {
            return false;
        }
        return item.type === FileType.IMAGE;
    }

    getImageUrl(file) {
        const size = '/medium';

        return `${this.config.fileStoragePicsUrl}${file.name}${size}`;
    }

    getFileUrl(item) {
        return '/assets/file_extension/' + getFileExtensionWidget(item.name) + '.png';
    }

    openLink(evt, link: Link) {
        if (link && link.isGDoc && link.isGDoc()) {
            evt.preventDefault();
            this.openInGDocOutlet(link.url);
        }
    }

    ngOnDestroy() {
        this.galleryService.photos$.next(null);
        this.galleryService.currentPhotoId$.next(null);
        this.galleryService.entityId$.next(null);
        this.destroyed.next();
        this.destroyed.complete();
    }

    getDownloadLink(file: File) {
        return `${this.config.fileStoragePicsUrl}${file.name}`;
    }

    onDownloadLinkClick($event, item) {
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        this.downloadFile(item);
        //saveAs(this.getDownloadLink(item), item.name);
    }
}
