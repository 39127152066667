import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {JSON} from 'ta-json';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';

export interface DadataItem {
  data: {
    inn: string;
    name: {
      full_with_opf: string;
      short_with_opf: string;
    };
    address: {
      value: string;
    };
  };
}

export interface DadataResult {
  suggestions: DadataItem[];
}

export enum OptionsStatus {
  NOT_INIT,
  LOADING,
  LOADED,
}

@Component({
  selector: 'app-inn-select',
  templateUrl: './inn-select.component.html',
  styleUrls: ['./inn-select.component.scss'],
})
export class InnSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedCompany: DadataItem;
  @Input() isDisabled = false;
  isEditingInn = false;

  @ViewChild('innInput') innInput: ElementRef;

  @Input() options: any[] = [];
  @Input() optionsStatus: OptionsStatus = OptionsStatus.NOT_INIT;

  @Input() simpleFormater = false;

  formatName(company) {
    let result = '';
    if (this.simpleFormater) {
      result = company.short_name;
    } else {
      result = company.data.name.short_with_opf;
    }

    if (result) {
      return result
    } else {
      return company.name || '–';
    }
  }

  formatInn(company) {
    let result = '';
    if (this.simpleFormater) {
      result = company.inn;
    } else {
      result = company.data.inn;
    }

    if (result) {
      return result
    } else {
      return 'не указан';
    }
  }

  statuses = OptionsStatus;


  @Output() onChange = new EventEmitter<any>();
  @Output() onKeyUp = new EventEmitter<any>();

  @Input() placeholder = 'Введите ИНН';
  @Input() requireInn = true;

  innQuery = new Subject<string>();
  destroyed = new Subject();

  constructor(
      readonly http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.innQuery.pipe(
        takeUntil(this.destroyed)
    ).subscribe(query => {
        this.optionsStatus = OptionsStatus.LOADING;
        this.options = [];
    });

    this.innQuery.pipe(
        debounceTime(500),
        takeUntil(this.destroyed)
    ).subscribe(query => {
        if (query.length >= 10) {
            this.optionsStatus = OptionsStatus.LOADING;
            this.options = [];
            this.updateDadata(query);
        } else {
          this.optionsStatus = OptionsStatus.LOADED;
          this.options = [];
        }
    });
  }

  onInnKeyUp(val: string) {
    setTimeout(() => {
      if (val && val.length) {
        this.innQuery.next(val);
      } else {
        this.innQuery.next(this.innInput.nativeElement.value);
      }
    })

  }

  onCompanySelected($event) {
    this.selectedCompany = $event.option.value;
    this.isEditingInn = false;

    // emit
    this.onChange.emit(this.selectedCompany);
  }

  formatDadataName(option: DadataItem) {
    return option.data.name.full_with_opf;
  }

  onSelectInnClick($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.isEditingInn = true;

    // hack
    setTimeout(() => this.innInput.nativeElement.focus(), 0);
  }

  displayFn() {
    return (val) => this.formatInn(val);
  }

  checkValDadata() {

    const val =  this.innInput?.nativeElement?.value.trim();

    if (val.length >= 10 && this.optionsStatus !== 0) {

      console.log(this.optionsStatus);
      if (this.optionsStatus !== OptionsStatus.LOADED) {
        console.log('still loading...');
        this.updateDadata(val, true);
        return;
      }

      const opt = this.options.find(o => o.data.inn === val);

      if (opt) {
        // авто выбор первого варианта
        this.selectedCompany = opt;
        this.isEditingInn = false;
        // emit
        this.onChange.emit(this.selectedCompany);
      } else {
        this.selectedCompany = {
          data: {
            inn: val,
            name: {
              full_with_opf: null,
              short_with_opf: null,
            },
            address: {
              value: null,
            },
          }
        };
        this.isEditingInn = false;
        // emit
        this.onChange.emit(this.selectedCompany);
      }
    } else {
      this.isEditingInn = true;
      //this.selectedCompany = null;
      this.onChange.emit(null);
    }
  }

  onInnBlur($event) {
    const target = $event.target;
    const val =  target.value.trim();

    console.log(this.optionsStatus);

      this.checkValDadata();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.isDisabled) {
      this.isDisabled = changes.isDisabled.currentValue;
    }

    if (changes.options) {
      this.options = changes.options.currentValue;
    }
    if (changes.optionsStatus) {
      this.optionsStatus = changes.optionsStatus.currentValue;

      if (this.optionsStatus === 2) {
        //this.checkValDadata();
      }
    }

    if (changes.selectedCompany) {
      this.selectedCompany = changes.selectedCompany.currentValue;
      console.log(this.selectedCompany);

      setTimeout(() => {
        if (this.selectedCompany?.data?.inn) {
          this.innInput.nativeElement.value = this.selectedCompany?.data?.inn
        }
      }, 0);
    }
  }

  updateDadata(query: string, andBlur = false) {
    // TODO move token out
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';
    const token = environment.dadataToken;

    const options = {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token
      }
    };

    this.optionsStatus = OptionsStatus.LOADING;

    return this.http.post(
        url,
        JSON.stringify({query}),
        options,
    ).pipe(
        map(res => JSON.deserialize<DadataResult>(res))
    ).subscribe(res => {
      this.options = res.suggestions;
      this.optionsStatus = OptionsStatus.LOADED;

      if (andBlur) {
        this.checkValDadata();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }

}
