<div class="learning-fileupload-anchor" tourAnchor="learningFileuploadDialog"></div>

<div role="alertdialog" class="grid dialog kit" (paste)="onPaste($event)"  (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)">
    <div class="sidebar">
        <div class="sidebar__section">
            <h2 class="heading">Прикрепить документ...</h2>
            <div class="upload-from-menu">
                <button class="upload-from-menu__item" (click)="fileUpload.click()" mat-ripple>
                    <mat-icon svgIcon="radius_upload" class="icon"></mat-icon>
                    С компьютера
                </button>
            </div>
            <input type="file" [disabled]="link.value" title="Загрузить файл" multiple (change)="onChangeFile($event)" id="fileUpload" #fileUpload class="file-input">
        </div>
        <div class="sidebar__section" tourAnchor="learningFileuploadLink">
            <h2 class="text">Прикрепить ссылку</h2>
            <input #link type="text" class="input" placeholder="Вставить ссылку" (paste)="onPaste($event)" />
            <input #name type="text" class="input" placeholder="Название" />
            <button class="radius-button--primary learningAddLink" [disabled]="!link.value || !name.value"
                (click)="onAddLinkButtonPressed($event, link, name)"
            >Прикрепить</button>
        </div>
    </div>
    <div class="files-area">
        <div class="files-area__upload" *ngIf="!hasFilesOrErrors()">
            <div class="files-area__header">
                <h2 class="heading">Добавление с компьютера</h2>
            </div>

            <div for="fileUpload" class="dragdrop-area" *ngIf="links.length === 0 && files.length === 0 && errors.length === 0">
                <div class="dragdrop-area__inner-block">
                    <svg class="dragdrop-area__cloud-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          fill="#a7acb2" width="80px" height="80px">
                        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69
                        6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/>
                    </svg>
                    <div class="dragdrop-area__heading">
                            Перетащите файлы в эту область<br />
                            или нажмите Ctrl+V для вставки из буфера обмена
                    </div>

                    <div  class="dragdrop-area__max-size-warning">Максимальный размер файла 100 Мб.</div>
                </div>
            </div>
        </div>

        <div class="files-area__list" *ngIf="hasFilesOrErrors()" [formGroup]="formGroup">
            <div class="files-area__header">
                <h2 class="heading">Загруженные</h2>
            </div>

            <div *ngFor="let item of errors" class="loader _error">
                <span class="loader-info">{{item.message}}</span>
            </div>

            <div class="loader" *ngIf="!isLoadedKB && isLoadedSubmit && isLoadedKBLinks">
                <span class="loader-info loading">Отправляю файлы в базу знаний</span>
            </div>

            <div class="loader" *ngIf="!isLoadedKBLinks && isLoadedKB && isLoadedSubmit">
                <span class="loader-info loading">Отправляю ссылки в базу знаний</span>
            </div>

            <div class="loader" *ngIf="!isLoadedSubmit && isLoadedKB && isLoadedKBLinks">
                <span class="loader-info loading">Прикрепляю файлы к хранилищу</span>
            </div>

            <div class="files-list">
                <ul class="files-list__item" *ngIf="files && docCount">
                    <h5 class="files-list__item-title">Документы ({{docCount}})</h5>
                    <ng-container *ngFor="let item of files; let index = index">
                        <li class="files__item" *ngIf="isDoc(item.file)">
                            <!-- In some cases, the icon is supplemental to a text label conveying the same meaning.
                                 In those cases, the alt attribute must be present but must be empty.
                                 https://html.spec.whatwg.org/multipage/images.html#a-short-phrase-or-label-with-an-alternative-graphical-representation:-icons,-logos -->
                            <img class="file__img" alt=""
                                 [src]="getFileUrl(item.file)">
                            <div class="txt-container">
                                <label class="label kit">
                                    <input
                                            class="input txt__item"
                                            formControlName={{item.id}}
                                            (blur)="onFocusChange(index,item)"
                                    />
                                </label>
                                <span class="txt__item _size">{{ item.size }}</span>
                            </div>
                            <app-buttons-kb *ngIf="gDocCondition" [index]="index">
                            </app-buttons-kb>
                            <button class="button-delete"
                                    (click)="deleteFile(index)">
                                <mat-icon class="icon _delete">close</mat-icon>
                            </button>
                        </li>
                    </ng-container>
                </ul>

                <ul class="files-list__item" *ngIf="links && links.length">
                    <h5 class="files-list__item-title">Ссылки ({{links.length}})</h5>
                    <ng-container *ngFor="let item of links, let index = index">
                        <li class="files__item--link">
                            <mat-icon *ngIf="getLink(item.link.url) === 'radius_link'" class="icon _link _common-link" svgIcon='radius_link'></mat-icon>
                            <mat-icon *ngIf="getLink(item.link.url) !== 'radius_link'" class="icon _link" svgIcon={{getLink(item.link.url)}}></mat-icon>
                            <a class="link" [href]="item.link['url'] | safeUrl" target="_blank" [matTooltip]="item.link['name'] ? item.link['name'] : item.link['url']">
                                {{ item.link['name'] ? item.link['name'] : item.link['url'] }}
                            </a>
                            <button mat-icon-button *ngIf="getLink(item.link.url) !== 'radius_link'" class="nb-button" [ngClass]="{'_active': this.links[index].flagKB}" (click)="saveLinkInKB(index)" matTooltip="Сохранить в Базу Знаний">
                                <mat-icon class="icon"  [ngClass]="{'_active': this.links[index].flagKB}" svgIcon="radius_book-open"></mat-icon>
                            </button>
                            <div *ngIf="getLink(item.link.url) === 'radius_link'"></div>
                            <button mat-icon-button class="button-delete learningDeleteLink"
                                    (click)="deleteLink(index)">
                                <mat-icon class="icon _delete">close</mat-icon>
                            </button>
                        </li>
                    </ng-container>
                </ul>

                <div class="files-list__item" *ngIf="files && fileCount">
                    <h5 class="files-list__item-title">Файлы ({{fileCount}})</h5>
                    <ng-container *ngFor="let item of files; let index = index">
                        <li class="files__item" *ngIf="!isDoc(item.file)">
                            <img class="file__img" alt=""
                                 [src]="getFileUrl(item.file)">
                            <div class="txt-container">
                                <label class="label kit">
                                    <input
                                            class="input txt__item"
                                            formControlName={{item.id}}
                                            (blur)="onFocusChange(index, item)"
                                    />
                                </label>
                                <span class="txt__item _size">{{ item.size }}</span>
                            </div>
                            <div> </div>
                            <button mat-icon-button class="button-delete"
                                    (click)="deleteFile(index)">
                                <mat-icon class="icon _delete">close</mat-icon>
                            </button>
                        </li>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <mat-checkbox *ngIf="hasImages()" class="checkbox-original checkbox-ellipsis" [(ngModel)]="uploadImagesAsFiles">
            Загружать изображения в исходном качестве
        </mat-checkbox>

        <button class="radius-button" (click)="close()">
            Отменить
        </button>
        <button class="radius-button--primary learningAddAttachment" [disabled]="!isLoadedKB || !isLoadedSubmit" (click)="save()" tourAnchor="learningFileuploadSave">
            Сохранить
        </button>
    </footer>
</div>

<div *ngIf="isLearningOpen" class="learning-background-overlay"></div>
