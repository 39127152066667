import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TasksService} from '../../../../services/tasks.service';
import {isParamBooleanIsset, Task} from '../../../../models/task';
import {takeUntil} from 'rxjs/operators';
import {ApiBoardsService} from '../../../../services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../../services/global-notification-center.service';
import {AuthService} from '../../../../../../services/auth.service';

@Component({
    selector: 'app-subtasks-form',
    templateUrl: './subtasks-form.component.html',
    styleUrls: ['./subtasks-form.component.scss']
})
export class SubtasksFormComponent implements OnInit, OnDestroy {
    public selectedOld: Task[] = [];
    public selected: Task[] = [];
    public selectedSorted: Task[] = [];
    public hiddenCnt = 0;

    public tasks: Task[] = [];
    public tasksAvailable: Task[] = [];
    public sorted: Task[] = [];
    public hideOld = false;

    public title = '';
    public isMultiple = false;
    public type = null;
    public searchTerm = '';
    public isLoaded = false;
// Триггер смерти компонента
    private destroyed = new Subject<void>();
    public confirmEditSubtasks = false;
    public changedSubTask = false;
    public addButton = null;
    allowCreate = true;
    showAllTasks = false;

    constructor(
        private api: ApiBoardsService,
        private notiService: GlobalNotificationCenterService,
        public dialogRef: MatDialogRef<any>,
        private tasksService: TasksService,
        private auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit() {
        this.selected = this.data.selected ? [].concat(this.data.selected) : [];
        this.selectedSorted = this.data.selected ? [].concat(this.data.selected) : [];
        if (this.data.hideOld) {
            this.hideOld = true;
            this.selectedOld = [].concat(this.data.selected);
        }

        if (this.data.showAllTasks) {
            this.showAllTasks = this.data.showAllTasks;
        }

        this.selectedVisible;

        if (this.hideOld) {
            this.hiddenCnt = this.selectedOld.length;
        } else {
            this.hiddenCnt = this.selectedSorted.filter(el => el.type && el.type !== 'task').length;
        }

        this.title = this.data.title;
        this.isMultiple = this.data.multiple;
        this.addButton = this.isMultiple ? '+ Создать подзадачу' : '+ Создать основную задачу';
        this.type = this.isMultiple ? 'child' : 'parent';
        this.getAvailableTasks();
        this.tasksService.confirmSubTasks$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    if (this.changedSubTask) {
                        this.confirmEditSubtasks = true;
                    } else {
                        this.close();
                    }
                }
            });

        if (!this.data.allowCreate) {
            this.allowCreate = false;
        }
    }

    get params() {
        let params = this.data.boardId ? `?b[]=${this.data.boardId}&` : '?';

        if (this.showAllTasks) {
            params = params + `all=true`;
            return params;
        }

        if (this.data.taskId) {
            params = params + (this.isMultiple ? `possible_descendants_of=${this.data.taskId}` :
                `possible_ancestors_of=${this.data.taskId}`);
        } else {
            params = `?possible_descendants_of=1`;
            return params;
        }

        if (!this.data.boardId) {
            params = params + `possible_descendants_of=1`;
        }

        return params;
    }

    getAvailableTasks() {
        this.getTasks(this.params)
    }

    getTasks(params) {
        this.api.getTasks(params)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.sorted = [].concat(res.data);
                this.tasks = [].concat(res.data);
                this.isLoaded = true;
                this.filterAvailables();

                if (this.searchTerm) {
                    this.onSearchChange(this.searchTerm)
                }
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    filterAvailables() {
        this.tasksAvailable = this.tasks.filter(el => !this.selected.find(member => member.id === el.id));

        this.selectedSorted = this.filterValues<Task>(this.searchTerm, this.selected);
        this.sorted = this.filterValues<Task>(this.searchTerm, this.tasksAvailable);
    }

    selectedVisible(task) {
        if (!this.hideOld) {
            return true;
        }

        return !this.selectedOld.some(el => el.id == task.id);
    }

    addTask(item: Task) {
        this.changedSubTask = true;
        if (this.isMultiple) {
            this.selected.push(item);
        } else {
            this.selected = [item];
            this.saveForm();
        }

        this.filterAvailables();

    }

    removeTask(item: Task) {
        this.changedSubTask = true;
        this.selected = this.selected.filter(el => el.id !== item.id);
        this.filterAvailables();
    }

    onSearchChange(search) {
        this.searchTerm = search;
        if (!this.isLoaded) {
            return false;
        }
        this.sorted = this.filterValues<Task>(search, this.tasksAvailable);
        this.selectedSorted = this.filterValues<Task>(search, this.selected);
    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }

    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['vid'], term))
            || (this.testString(item['title'], term));
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }

    close() {
        this.tasksService.confirmSubTasks$.next(null);
        this.dialogRef.close();
    }

    add() {
        this.dialogRef.close('add');
    }

    saveForm() {
        this.filterAvailables();
        this.dialogRef.close(this.selected);
    }

    isAvailable(param) {
        return isParamBooleanIsset(param);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
