import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'SortRangesByMin'
})
export class SortRangesByMin implements PipeTransform {
    transform(value: any[]): any[] {
        return value?.sort((n1,n2) =>
        {
            return n1?.min - n2?.min;
        });
    }

}
