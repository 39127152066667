import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {User} from '../../../models/user';
import {JSON} from 'ta-json';
import {Subdivision} from '../models/subdivision';
import {Application} from '../models/application';
import {PhoneBookSettingsView} from '../models/settings-phonebook-view';
import {SettingsPhonebook} from '../models/settings-phonebook';
import {PositionShort} from '../models/position-short';
import {Filter} from '../../task-manager/models/filter';
import {FiltersAvailable} from '../../task-manager/models/filters-available';

@Injectable({
    providedIn: 'root'
})
export class PhonebookService {

    public phonebookApps$ = new BehaviorSubject<Application[]>(null);
    public canEdit$ = new BehaviorSubject<boolean>(null);
    public isAdmin$ = new BehaviorSubject<boolean>(null);
    public baseListUsers$ = new BehaviorSubject<User[]>(null);
    public users$ = new BehaviorSubject<User[]>(null);
    public subdivisions$ = new BehaviorSubject<Subdivision[]>(null);
    public positions$ = new BehaviorSubject<PositionShort[]>(null);
    public settings$ = new BehaviorSubject<SettingsPhonebook>(null);
    public settingsView$ = new BehaviorSubject<PhoneBookSettingsView>(null);
    public hideUser$ = new Subject<User>();
    public favoriteUsers$ = new BehaviorSubject<User>(null);

    public subdivisionCreated$ = new BehaviorSubject<Subdivision>(null);
    public subdivisionUpdated$ = new Subject<Subdivision>();

    public positionCreated$ = new BehaviorSubject<PositionShort>(null);

    public scrolledArchive$ = new Subject<any>();

    public searchTerm$ = new BehaviorSubject<string>('');
    public reset$ = new Subject();
    public filtersMenuOpened$ = new BehaviorSubject<boolean>(false);

    public totalCnt$ = new BehaviorSubject<number>(0);
    public hiddenCnt$ = new BehaviorSubject<number>(0);
    public getUsers$ = new Subject();
    public getArchiveSearch$ = new Subject();
    public getArchiveFilters$ = new Subject();
    public updateUser$ = new Subject<User>();
    public getSubdivisions$ = new Subject();
    public createdUser$ = new BehaviorSubject(false);
    public isProd$ = new BehaviorSubject(false);
    public openedUser$ = new BehaviorSubject(null);
    // Флаг рткрытия архива
    public archive$ = new BehaviorSubject(false);
    public newUsersChunk$ = new BehaviorSubject<User[]>(null);

    filtersOpened$ = new BehaviorSubject(false);
    filtersSelectedOpened$ = new BehaviorSubject(false);
    saveFilters$ = new Subject();
    resetFilters$ = new Subject();

    public filtersAvailable = new BehaviorSubject<FiltersAvailable>(null);
    public filters$ = new BehaviorSubject<SettingsPhonebook>(null);
   // public tasksFilters$ = new BehaviorSubject<Filter>(null);
    public taskCreateHelperOpened$ = new Subject();
    public selectedGroup$ = new BehaviorSubject(null);


    constructor() {
    }

    saveLocalSettings(settings: SettingsPhonebook, userId) {
        localStorage.setItem('phoneBookSettings' + userId, JSON.stringify(settings));
    }

    getLocalSettings(userId) {
        const saved = localStorage.getItem('phoneBookSettings' + userId);
        if (saved) {
            this.settings$.next(JSON.parse(saved, SettingsPhonebook));
        } else {
            const settings = new SettingsPhonebook();
            this.settings$.next(settings);
        }
    }
}

/**
 * Список статусов
 */
export const ABCMAP = {
    favorites: 'key0',
    hidden: 'key30',
    A: 'key1',
    Б: 'key2',
    В: 'key3',
    Г: 'key4',
    Д: 'key5',
    Е: 'key6',
    Ё: 'key7',
    Ж: 'key8',
    З: 'key9',
    И: 'key10',
    К: 'key11',
    Л: 'key12',
    М: 'key13',
    Н: 'key14',
    О: 'key15',
    П: 'key16',
    Р: 'key17',
    С: 'key18',
    Т: 'key19',
    У: 'key20',
    Ф: 'key21',
    Х: 'key22',
    Ц: 'key23',
    Ч: 'key24',
    Ш: 'key25',
    Щ: 'key26',
    Ы: 'key27',
    Ю: 'key28',
    Я: 'key29',
};
