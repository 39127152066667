import {JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from './user';

@JsonObject()
export class Permission {

    @JsonProperty('id')
    @JsonType(String)
    id: string;

    @JsonProperty('title')
    @JsonType(String)
    title: string;

    constructor(
        id: string = null,
        title: string = null
    ) {
        this.id = id;
        this.title = title;
    }
}


@JsonObject()
export class Permissions {

    @JsonProperty('permission')
    @JsonElementType(Permission)
    permission: Permission;

    @JsonProperty('user')
    @JsonElementType(UserShort)
    user: UserShort;

    constructor(
        permission: Permission = null,
        user: UserShort = null
    ) {
        this.permission = permission;
        this.user = user;
    }
}

@JsonObject()
export class Application {
    @JsonProperty('is_hidden')
    @JsonElementType(Boolean)
    is_hidden: boolean;

    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('logo')
    @JsonType(String)
    logo: string;

    @JsonProperty('view_name')
    @JsonType(String)
    viewName: string;


    constructor(
        id: number = null,
        name: string = null,
        logo: string = null,
        viewName: string = null
    ) {
        this.id = id;
        this.name = name;
        this.logo = logo;
        this.viewName = viewName;
    }
}

@JsonObject()
export class Sections {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('logo')
    @JsonType(String)
    logo: string;

    @JsonProperty('view_name')
    @JsonType(String)
    viewName: string;

    @JsonProperty('permissions')
    @JsonElementType(Permissions)
    permissions: Permissions[];

    @JsonProperty('rights')
    @JsonElementType(Permission)
    rights: Permission[];



    constructor(
        id: number = null,
        name: string = null,
        logo: string = null,
        viewName: string = null,
        permissions: Permissions[] = [],
        rights: Permission[] = [],
    ) {
        this.id = id;
        this.name = name;
        this.logo = logo;
        this.viewName = viewName;
        this.permissions = permissions;
        this.rights = rights;
    }

}

@JsonObject()
export class Permits {
    @JsonProperty('application')
    @JsonElementType(Application)
    application: Application;

    @JsonProperty('sections')
    @JsonElementType(Sections)
    sections: Sections[];

    constructor(
        application: Application = null,
        sections: Sections[] = []
    ) {
        this.application = application;
        this.sections = sections;
    }
}
