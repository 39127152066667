import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

import { MONTHS_SHORT } from '../commons';

/**
 * Пайп форматирует даты в наш принятый формат
 */
@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    transform(date: string | Date | moment.Moment | null): any {
        const momentObj = moment(date);

        if (!date || !momentObj.isValid()) {
            return '-';
        }

        const day = momentObj.format('DD');
        const month = momentObj.get('month');
        const year = momentObj.get('year');

        if (year !== moment().get('year')) {
            return `${day} ${MONTHS_SHORT[month]} ${year}`;
        } else {
            return `${day} ${MONTHS_SHORT[month]}`;
        }
    }
}
