import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationCenterService} from '../../modules/notification-center/services/notification-center.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
        private noti: NotificationCenterService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                if (this.auth.auth) {
                    this.noti.handleError('Вы больше не авторизованы на платформе');
                }
                this.router.navigate(['/auth/sign-out']);
            }
            return throwError(err);
        }));
    }
}
