import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import * as moment from 'moment';
import {Schedule} from './schedule';
import { DAYS_OF_WEEK } from 'src/app/utils/commons';

export class Template {
    // Id шаблона
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    // Id Задачи
    @JsonProperty('task_id')
    @JsonType(Number)
    task_id: number = null;

    // Повторять каждые
    // Значение
    @JsonProperty('repeat_every_value')
    @JsonType(Number)
    repeat_every_value: number = null;
    // Селект выбора
    @JsonProperty('repeat_every_unit')
    @JsonType(String)
    repeat_every_unit: string = 'week';
    // Массив дней или чисел
    @JsonProperty('repeat_every_days')
    @JsonType(Array)
    repeat_every_days = [];
    // Начало повтора
    @JsonProperty('repeat_start_date')
    @JsonType(Date)
    repeat_start_date: Date = null;
    // Кол-во повторений
    @JsonProperty('repeat_until_count')
    @JsonType(Number)
    repeat_until_count: number = null;
    // Повторять до даты
    @JsonProperty('repeat_until_date')
    @JsonType(Date)
    repeat_until_date: Date = null;

    ///////////////////////////////////////

    //  Дата начала за N дней до выполнения
    @JsonProperty('task_start_date_days_before')
    @JsonType(Number)
    task_start_date_days_before: number = 0;

    // Создать за N дней до начала
    @JsonProperty('task_create_date_days_before')
    @JsonType(Number)
    task_create_date_days_before: number = 0;

    //Создать N экземпляров
    @JsonProperty('task_pre_created_count')
    @JsonType(Number)
    task_pre_created_count: number = null;

    ///////////////////////////////////////

    // Игнорировать праздничные дни
    @JsonProperty('ignore_holidays')
    @JsonType(Number)
    ignore_holidays: number = 0;


    @JsonProperty('next_date')
    @JsonType(Date)
    next_date: Date = null;

    @JsonProperty('schedule')
    @JsonElementType(Schedule)
    schedule: Schedule[] = [];

    @JsonProperty('notifiable_roles')
    @JsonType(Array)
    notifiable_roles = [];

    @JsonProperty('task')
    @JsonType(Object)
    task: Object = null;
}

export function getRepeatValues(template: Template) {
    let str = '';
    if (template.repeat_every_days.length) {
        let arrTxt = '';
        if (template.repeat_every_unit == 'week') {
            arrTxt = template.repeat_every_days.map(el => ' ' + DAYS_OF_WEEK[el]).toString();
            str = str + ' По ' + arrTxt;
        }

        if (template.repeat_every_unit == 'quater') {
            const YEAR = [
                ['I', 'Янв','Фев','Мар'],
                ['II','Апр','Май','Июн'],
                ['III','Июл','Авг','Сен'],
                ['IV','Окт','Ноя','Дек']
            ];

            str = YEAR.map(el => {
                return el[template.repeat_every_value]
            }).join(', ').toString() + ' | ';
        }

        if (template.repeat_every_unit == 'quater' || template.repeat_every_unit == 'month') {
            let arr = [...template.repeat_every_days];
            const last = arr.findIndex(el => el === 31);
            if (last >= 0) {
                arr.splice(last, 1);
            }

            arr.sort(function(a, b) {
                return a - b;
            });

            arrTxt = arr.map(el => ' ' + (el + 1)).toString();

            if (arr.length === 1) {
                str = str + arrTxt + ' числа';
            } else {
                if (    arr.length) {
                    str = str + 'По '+ arrTxt + ' числам';
                }
            }

            if (last >= 0) {
                if (arr.length >= 1) {
                    str = str + ', В последний день месяца'
                } else {
                    str = str + 'В последний день месяца'
                }

            }
        }
    }

    return str;
}

export function finishDateByCount(template: Template) {
    let start = template.repeat_start_date;
    let cnt = template.repeat_until_count;
    let delay = template.repeat_every_value;
    let run = template.repeat_every_unit;
    let res = moment(start);
    switch (run) {
        case('day'):
            res.add(cnt * delay, 'day');
            break;
        case('week'):
            res.add(cnt * delay, 'week');
            break;
        case('month'):
            res.add(cnt * delay, 'month');
            break;
        case('year'):
            res.add(cnt * delay, 'year');
            break;
    }

    if (template.schedule && template.schedule.length) {
        return template.schedule[template.schedule.length - 1].date
    }

    return res.toDate();

}

export function getRepeatUnit(template: Template) {
    let value = '';

    switch (template.repeat_every_unit) {
        case 'day':
            value = 'день';
            break;
        case 'week':
            value = 'неделю';
            break;
        case 'month':
            value = 'месяц';
            break;
        case 'year':
            value = 'год';
            break;
        case 'quater':
            value = 'квартал';
            break;
    }

    let str = template.repeat_every_unit == 'week' ? 'Каждую ' : 'Каждый ';
    let cnt = template.repeat_every_unit !== 'year' && template.repeat_every_unit !== 'quater' ? template.repeat_every_value : '';
    return str + cnt + ' ' + value;
}
